<template>
    
    <loader :active="loading" message="Please wait..." />

    <!-- <div class="admin-header-bottom">
        <nav class="admin-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Credits History</li>
            </ol>
        </nav>
        <h1 class="page-title">Credits History</h1>
    </div> -->

    <div class="admin-body">
        <div class="admin-box-row row">
            <div class="admin-box-col col-xs-12 col-md-12 col-lg-12">
                <div class="data-table-cont no-pagination">
                    <div class="table-responsive table-credits-history">
                        <table id="example" class="data-table table table-striped table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap min-width200">Credits History</th>
                                    <th class="no-wrap">Details</th>
                                    <!-- <th>Domain</th> -->
                                    <th class="no-wrap">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(credits, index) in creditsHistory" :key="index">
                                    <td class="no-wrap" :style="{ color: credits.is_added ? 'green' : 'red' }">{{
                                    credits.is_added ? '+ ' : '- ' }}{{
                                    credits.keywords_count }}</td>
                                    <td class="no-wrap">{{ credits.details  }}</td>
                                    <!-- <td>{{ credits.url || 'N/A' }}</td> -->
                                    <td class="no-wrap responsive-wrap max-width110-mobile">{{ credits.date_time || 'N/A' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div>

</template>

<script>
import axiosIns from '@/axios';
import Loader from '../Loader.vue';

export default {
    components: {
        Loader
    },
    data() {
        return {
            creditsHistory: [],
            loading:false
        };
    },
    methods: {

        async fetchCreditsHistory() {
            this.loading = true
            try {
                const response = await axiosIns.get('/user/credits-history');
                this.loading = false
                return response.data.history;
            } catch (error) {
                console.error('Error fetching plans:', error);
                return [];
            }
        },

        async loadCreditsHistory() {
            this.creditsHistory = await this.fetchCreditsHistory();
        },

    },
    mounted() {
        this.loadCreditsHistory();
    },
};


</script>

<style scoped></style>