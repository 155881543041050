<template>
    <div class="common-inner-body drc-page-cont">
        <div class="common-inner-cont">
      
            <!-- Conditional Upgrade Message -->
            <!-- <div v-if="showUpgradeMessage || userType === 1" class="upgrade-message-container"> -->
            <div class="upgrade-message-container">
                <h2 class="upgrade-title">Your Free Plan Is Over</h2>
                <p class="upgrade-text">Please Upgrade Your Plan To Continue</p>
                <div class="upgrade-btn-cont">
                    <a class="upgrade-link" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-add-project-pricing-plan">
                        <img class="icon-img" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-crown.svg" alt="Icon Crown" /> Upgrade
                    </a>
                </div>
            </div>

            <div class="search-page-top create-project-top">
                <h2 class="top-title">Create Serp Project</h2>
                <h2 class="top-subtitle">
                    Enter Your Details Below to Create a New Serp Project
                    <strong>And Effortlessly Track Domain Performance</strong>
                </h2>

                <div class="top-form-cont mt-30">
                    <div class="multistep-form">
                        <!-- Step Number Navigation with Lines -->
                        <div class="steps">
                            <div v-for="(step, index) in steps" :key="index" class="step-container">
                                <!-- Line (before step, except the first) -->
                                <!-- <div v-if="index > 0" class="line" :class="{ active: currentStep >= index }"></div> -->
                        
                                <!-- Step Number -->
                                <div class="step" :class="{ active: currentStep === index, completed: currentStep > index }" @click="goToStep(index)" >
                                    <p class="step-number">{{ index + 1 }}</p>
                                    <p class="step-title for-desktop">Project Info</p>
                                </div>
                        
                                <!-- Line (after step, except the last) -->
                                <div v-if="index < steps.length - 1" class="line" :class="{ active: currentStep >= index + 1 }"></div>
                            </div>
                        </div>
                    
                        <!-- Form Steps -->
                        <div class="form-content">
                            <div class="form-box box-one" v-show="currentStep === 0">
                                <!-- <h2>Step 1</h2>
                                <p>Enter details for step 1.</p>
                                <input type="text" placeholder="Step 1 Input" v-model="formData.step1" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont your-project-name">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" placeholder="Enter Project Name" />
                                            </div>
                                        </div>
                                        <div class="search-fld-cont search-enging">
                                            <div class="input-fld-cont">
                                                <select class="form-control">
                                                    <option value="">Select Search Engine</option>
                                                    <option value="" selected>Google</option>
                                                    <option value="">Yahoo</option>
                                                    <option value="">Bing</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="search-fld-cont search-device">
                                            <div class="input-fld-cont">
                                                <select class="form-control">
                                                    <option value="">Select Device</option>
                                                    <option value="" selected>Desktop</option>
                                                    <option value="">Tablet</option>
                                                    <option value="">Mobile</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="search-fld-cont search-language">
                                            <div class="input-fld-cont">
                                                <select class="form-control">
                                                    <option value="">Select Language</option>
                                                    <option value="" selected>English</option>
                                                    <option value="">Language 2</option>
                                                    <option value="">Language 3</option>
                                                    <option value="">Language 4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-two" v-show="currentStep === 1">
                                <!-- <h2>Step 2</h2>
                                <p>Enter details for step 2.</p>
                                <input type="text" placeholder="Step 2 Input" v-model="formData.step2" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont your-domain">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" placeholder="Your Domain" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20 w-100 max-w-100">
                                        <!-- Keywords Section -->
                                        <div class="search-fld-cont search-tag-box">
                                            <label>Keywords</label>
                                            <div class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                                <input class="form-control" type="text" placeholder="Enter your keywords for search" />
                                                <a class="fld-add-icon" href="javascript:void(0);">
                                                    <img class="img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-add.svg" alt="Icon Reset" />
                                                </a>
                                            </div>
                                            <div class="input-fld-cont search-tag-cont">
                                                <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                                    <li class="competitor-item d-flex align-items-center">keyword 1</li>
                                                    <li class="competitor-item d-flex align-items-center">keyword 2</li>
                                                    <li class="competitor-item d-flex align-items-center">keyword 3</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- Competitors Section -->
                                        <div class="search-fld-cont search-tag-box">
                                            <label>Competitors</label>
                                            <div class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                                <input class="form-control" type="text" placeholder="Enter domains, Max 3 domains" />
                                                <a class="fld-add-icon" href="javascript:void(0);">
                                                    <img class="img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-add.svg" alt="Icon Reset" />
                                                </a>
                                            </div>
                                            <div class="input-fld-cont search-tag-cont">
                                                <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                                    <li class="competitor-item d-flex align-items-center">domain 1</li>
                                                    <li class="competitor-item d-flex align-items-center">domain 2</li>
                                                    <li class="competitor-item d-flex align-items-center">domain 3</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-three" v-show="currentStep === 2">
                                <!-- <h2>Step 3</h2>
                                <p>Enter details for step 3.</p>
                                <input type="text" placeholder="Step 3 Input" v-model="formData.step3" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont search-country">
                                            <div class="input-fld-cont">
                                                <select class="form-control">
                                                    <option selected>Select a Country</option>
                                                    <option>Country 1</option>
                                                    <option>Country 2</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="search-fld-cont search-state-city">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" placeholder="Enter State and City optional" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-four" v-show="currentStep === 3">
                                <!-- <h2>Step 4</h2>
                                <p>Enter details for step 4.</p>
                                <input type="text" placeholder="Step 4 Input" v-model="formData.step4" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont project-run-time">
                                            <div class="input-fld-cont">
                                                <div class="form-check form-check-big d-flex align-items-center">                                                    
                                                    <label class="form-check-label" for="exampleCheck1">Run Once a Day <span class="text-primary-new">(Recommended)</span></label>
                                                    <input id="exampleCheck1" class="form-check-input" type="checkbox" checked>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="search-fld-cont project-run-time w-100">
                                            <div class="input-fld-cont">
                                                <select class="form-control">
                                                    <option selected>Project Run Time</option>
                                                    <option>Project Run Time 1</option>
                                                    <option>Project Run Time 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <!-- Navigation Buttons -->
                        <div class="form-navigation">
                            <button class="form-btn btn-reset" :disabled="currentStep === 0" @click="prevStep"><img class="icon-img" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-reset-multistep-form.svg" alt="Icon Reset" /> Reset</button>
                            <button class="form-btn btn-next" v-if="currentStep < steps.length - 1" @click="nextStep"><img class="icon-img" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-next-multistep-form.svg" alt="Icon Reset" /> Next Step</button>
                            <button class="form-btn btn-submit" v-else @click="submitForm">Submit</button>
                        </div>
                    </div>
                </div>

                <h2 class="bottom-title">Effortlessly Manage Keywords and Track<br>Monthly Rankings to Enhance Your SEO Strategy</h2>
                <h2 class="bottom-subtitle">Take control of your SEO with Serp Tracker! Set up keyword details for your domain, monitor rankings monthly, and gain actionable insights. Our user-friendly dashboard makes it easy to analyze trends and refine your strategy for consistent SEO growth.</h2>

                <div class="search-page-img-cont">
                    <img class="img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/create-serp-project-page-bg.png" alt="Photo" />
                </div>
            </div>

        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">
        &copy; {{ currentYear }} <!--2024--> Plus Promotions UK LTD | Registered in UK, Company Number
        13871077, VAT Number 438499640
        </p>
    </div>

</template>
  
<script>
    export default {
        data() {
            return {
                currentYear: new Date().getFullYear(),
                currentStep: 0,
                steps: [1, 2, 3, 4],
                formData: {
                step1: '',
                step2: '',
                step3: '',
                step4: '',
                },
            };
        },
        methods: {
        nextStep() {
            if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
            }
        },
        prevStep() {
            if (this.currentStep > 0) {
            this.currentStep--;
            }
        },
        goToStep(step) {
            this.currentStep = step;
        },
        submitForm() {
            console.log('Form Submitted:', this.formData);
            alert('Form Submitted!');
        },
        },
    };
</script>  