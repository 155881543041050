<template>
    <div class="common-inner-body create-serp-page-cont">
        <div class="common-inner-cont">
            <div class="upgrade-message-container" v-if="userData?.type == 1">
                <h2 class="upgrade-title">Your Free Plan Is Over</h2>
                <p class="upgrade-text">Please Upgrade Your Plan To Continue</p>
                <div class="upgrade-btn-cont">
                    <a class="upgrade-link" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-add-project-pricing-plan">
                        <img class="icon-img" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-crown.svg" alt="Icon Crown" /> Upgrade
                    </a>
                </div>
            </div>
            <div class="create-serp-search">

                <h2 class="top-title">Check Your Serp Easily</h2>
                <h2 class="top-subtitle">
                    Enter Your Details Below to Check the Latest Numbers for
                    <br>
                    <strong>Keyword Ranking and more</strong>
                </h2>
                <form @submit.prevent="submitForm" class="serp-search-form-cont">
                    <div>
                        <div class="serp-search-box">
                            <div
                                class="search-fld-row first-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <div class="search-fld-cont your-domain">
                                    <div class="input-fld-cont">
                                        <input class="form-control" type="text" placeholder="Your Domain"
                                            v-model="formData.domainUrl" @input="validateDomain" />
                                        <span v-if="domainValidationError" class="error-message">
                                            Please enter a valid domain. Example: 'google.com', 'amazon.com', or
                                            'example.co.uk'.".
                                        </span>
                                    </div>
                                </div>

                                <div class="search-fld-cont search-enging">
                                    <div class="input-fld-cont">
                                        <select class="form-control" v-model="formData.searchEngine"
                                            :disabled="userData?.type == 1" @change="validateSearchEngine">
                                            <option value="">Select Search Engine</option>
                                            <option v-for="engine in searchEngine" :key="engine.id" :value="engine.id">
                                                {{ engine.name }}
                                            </option>
                                        </select>
                                        <span class="required-alert" v-show="userData?.type == 1">
                                            Upgrade to view more options
                                        </span>
                                        <span v-if="searchEngineError" class="error-message">Please select a search
                                            engine.</span>
                                    </div>
                                </div>

                                <div class="search-fld-cont search-device">
                                    <div class="input-fld-cont">
                                        <select class="form-control" v-model="formData.deviceType"
                                            :disabled="userData?.type == 1" @change="validateDeviceType">
                                            <option value="">Select Device</option>
                                            <option v-for="device in deviceType" :key="device.id" :value="device.id">
                                                {{ device.name }}
                                            </option>
                                        </select>
                                        <span class="required-alert" v-show="userData?.type == 1">
                                            Upgrade to view more options
                                        </span>
                                        <span v-if="deviceTypeError" class="error-message">Please select a
                                            device.</span>
                                    </div>
                                </div>

                                <div class="search-fld-cont search-language">
                                    <div class="input-fld-cont">
                                        <select class="form-control" v-model="formData.language"
                                            :disabled="userData?.type == 1" @change="validateLanguage">
                                            <option value="">Select Language</option>
                                            <option v-for="language in languages" :key="language.code"
                                                :value="language.code">
                                                {{ language.name }}
                                            </option>
                                        </select>
                                        <span class="required-alert" v-show="userData?.type == 1">
                                            Upgrade to view more options
                                        </span>
                                        <span v-if="languageError" class="error-message">Please select a
                                            language.</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="search-fld-row second-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <div class="search-fld-cont search-country">
                                    <div class="input-fld-cont">
                                        <select class="form-control" v-model="formData.country"
                                            @change="onCountryChange">
                                            <option value="">Select a Country</option>
                                            <option v-for="country in regionList" :key="country.country_iso_code"
                                                :value="country">
                                                {{ country.location_name }}
                                            </option>
                                        </select>
                                        <span v-if="countryError" class="error-message">Please select a country.</span>
                                    </div>
                                </div>

                                <div class="search-fld-cont search-state-city">
                                    <div class="input-fld-cont">
                                        <input class="form-control" type="text" v-model="searchQuery" @input="onChange"
                                            :disabled="formData.country === null"
                                            placeholder="Enter State and City optional" />
                                        <ul v-show="showResults" class="autocomplete-results">
                                            <li v-for="(result, index) in searchResults" :key="index"
                                                class="autocomplete-result" @click="selectResult(result)">
                                                {{ result.location_name }}
                                            </li>
                                            <li v-if="searchResults?.length === 0 && searchQuery.trim() !== ''"
                                                class="autocomplete-no-results">
                                                No results found
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="search-fld-row third-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <!-- Keywords Section -->
                                <div class="search-fld-cont search-tag-box">
                                    <label>Keywords</label>
                                    <div
                                        class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                        <input v-model="newKeyword" class="form-control" type="text"
                                            placeholder="Enter your keywords for search" />
                                        <a class="fld-add-icon" href="#" @click.prevent="addKeyword">
                                            <img class="img-fluid"
                                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-add.svg"
                                                alt="Icon Reset" />
                                        </a>
                                    </div>
                                    <span v-if="showKeywordError" class="error-message">Keywords cannot be empty.</span>

                                    <div class="input-fld-cont search-tag-cont">
                                        <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                            <li v-for="(keyword, index) in formData.keyword" :key="index"
                                                class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">{{ keyword }}</span>
                                                <button @click="removeKeyword(index)" class="remove-btn"
                                                    aria-label="Remove competitor">
                                                    ✕
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="search-fld-cont search-tag-box"
                                    :class="{ 'disabled-section': userData?.type === 1 }">
                                    <label>Competitors</label>
                                    <div
                                        class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                        <input v-model="newCompetitor" class="form-control" type="text"
                                            placeholder="Enter domains, Max 3 domains"
                                            :disabled="userData?.type === 1" />
                                        <a class="fld-add-icon" href="#"
                                            @click.prevent="userData?.type !== 1 && addCompetitor">
                                            <img class="img-fluid"
                                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-add.svg"
                                                alt="Icon Reset" />
                                        </a>
                                    </div>
                                    <span v-if="competitorError" class="error-message">
                                        {{ competitorErrorMessage }}
                                    </span>
                                    <span v-if="userData?.type == 1" class="error-message">
                                        Upgrade to view more options
                                    </span>
                                    <div class="input-fld-cont search-tag-cont">
                                        <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                            <li v-for="(competitor, index) in formData.competitor" :key="index"
                                                class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">{{ competitor }}</span>
                                                <button @click="userData?.type !== 1 && removeCompetitor(index)"
                                                    class="remove-btn" aria-label="Remove competitor">
                                                    ✕
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div
                                class="search-fld-row btn-row d-flex flex-wrap justify-content-center col-gap-20 row-gap-10 max-w-100">
                                <div class="search-fld-cont btn-cont mb-0">
                                    <button class="admin-common-btn btn-reset" type="button" @click="resetConfirm">
                                        <span>
                                            <img class="img-fluid"
                                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-reset.svg"
                                                alt="Icon Reset" />
                                            Reset
                                        </span>
                                    </button>
                                </div>
                                <div class="search-fld-cont btn-cont mb-0">
                                    <button class="admin-common-btn btn-ranking" type="submit"
                                        :disabled="isSubmitDisabled">
                                        <span>
                                            <img class="img-fluid"
                                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-search-ranking.svg"
                                                alt="Icon Ranking" />
                                            Ranking
                                        </span>
                                    </button>
                                    <!-- Conditional message when the button is disabled -->

                                </div>


                            </div>
                            <p v-if="isSubmitDisabled" style="color: red !important;" class="text-muted mt-2">
                                Please fill above all the inputs to enable the button.
                            </p>
                        </div>

                    </div>
                </form>
                <h2 class="bottom-title">Get Real-Time SERP Results and Effortlessly<br>Track Your Keyword Rankings for
                    Better SEO Performance</h2>
                <h2 class="bottom-subtitle">Stay ahead in SEO with Serp Plus! Get instant access to the latest SERP
                    results and track your keyword rankings easily. Our simple dashboard provides real-time insights to
                    help you improve your SEO and beat the competition</h2>

                <div class="search-page-img-cont">
                    <img class="img-fluid"
                        src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/create-serp-page-bg.png"
                        alt="Create Serp Page BG" />
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">
            &copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number
            13871077, VAT Number 438499640
        </p>
    </div>
</template>



<script setup>
import { ref, onMounted, computed, watch, reactive } from 'vue';
import CountryService from '@/service/CountryService';
import SerpService from '@/service/SerpService';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import EventBus from '@/EventBus';
import { useRouter } from 'vue-router';
const router = useRouter();

const countryService = new CountryService();
const serpService = new SerpService();

const regionList = ref(null);
const cityStateData = ref([]);

const newKeyword = ref("");
const newCompetitor = ref("");
const showKeywordError = ref(false);
const competitorError = ref(false);
const competitorErrorMessage = ref("");

const generateRandomId = () => {
    const randomString = Math.random().toString(36).substring(2, 8);

    const randomNumber1 = Math.floor(Math.random() * 10);
    const randomNumber2 = Math.floor(Math.random() * 10);

    const randomId = randomString + randomNumber1 + randomNumber2;

    return randomId;
};

const formData = reactive({
    random_id: generateRandomId(),
    deviceType: null,
    language: 'en',
    domainUrl: "",
    keyword: [],
    competitor: [],
    country: null,
    state_city: null,
    location_name: null,
    option_location: null,
});


const domainValidationError = ref(false);

const validateDomain = () => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    domainValidationError.value = !domainPattern.test(formData.domainUrl.trim());
};

const validateCompetitor = (domain) => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    const trimmedDomain = domain.trim();

    if (!domainPattern.test(trimmedDomain)) {
        competitorError.value = true;
        competitorErrorMessage.value = "Please enter a valid domain. Example: 'google.com', 'amazon.com', or 'example.co.uk'.";
        return false;
    }

    if (formData.competitor.some((existingDomain) => existingDomain.toLowerCase() === trimmedDomain.toLowerCase())) {
        competitorError.value = true;
        competitorErrorMessage.value = "This competitor is already added.";
        return false;
    }

    competitorError.value = false;
    competitorErrorMessage.value = "";
    return true;
};



watch(
    () => formData.domainUrl,
    (newValue) => {
        if (newValue === "") {
            domainValidationError.value = false;
        } else {
            validateDomain();
        }
    }
);

watch(
    () => formData.searchEngine,
    () => validateSearchEngine()
);

watch(
    () => formData.deviceType,
    () => validateDeviceType()
);

watch(
    () => formData.language,
    () => validateLanguage()
);

const countryError = ref(false);

const searchEngineError = ref(false);
const deviceTypeError = ref(false);
const languageError = ref(false);

const validateSearchEngine = () => {
    searchEngineError.value = formData.searchEngine === "";
};

const validateDeviceType = () => {
    deviceTypeError.value = formData.deviceType === "";
};

const validateLanguage = () => {
    languageError.value = formData.language === "";
};

const addKeyword = () => {
    if (newKeyword.value.trim()) {
        formData.keyword.push(newKeyword.value.trim());
        newKeyword.value = "";
        showKeywordError.value = false;
    } else {
        showKeywordError.value = true;
    }
};

const removeKeyword = (index) => {
    formData.keyword.splice(index, 1);
};

const addCompetitor = () => {
    if (formData.competitor.length >= 3) {
        competitorError.value = true;
        competitorErrorMessage.value = "You can add up to 3 competitors only.";
        return;
    }

    if (!validateCompetitor(newCompetitor.value)) {
        competitorError.value = true;
        competitorErrorMessage.value = "Please enter a valid domain.";
        return;
    }

    formData.competitor.push(newCompetitor.value.trim());
    newCompetitor.value = "";
    competitorError.value = false;
};


const removeCompetitor = (index) => {
    formData.competitor.splice(index, 1);
};

const stateData = reactive({})

const submitForm = () => {
    validateDomain();
    validateSearchEngine();
    validateDeviceType();
    validateLanguage();

    const isValid =
        !domainValidationError.value &&
        !searchEngineError.value &&
        !deviceTypeError.value &&
        !languageError.value &&
        !countryError.value &&
        formData.keyword.length > 0

    if (!isValid) {
        toast.error('Please fill out all required fields correctly.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        return;
    }

    if (formData.keyword.length > 100) {
        toast.error('Serpplus does not support more than 100 keywords. Please reduce the number of keywords.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        return;
    }

    Object.assign(formData, {
        country: formData.country?.location_code ?? null,
        state_city: stateData?.location_code ?? null,
        location_name: stateData?.location_name ?? null,
        option_location: stateData?.location_name ?? null,
    });

    serpService.checkSerp(formData)
        .then((data) => {
            setTimeout(() => {
                EventBus.$emit('loadSerps', true);
                router.push('/view-serps');
            }, 10000);

            toast.success('Keywords are in queue. Processing will take a maximum of 3 to 5 minutes. If any keywords are missing, please check again after that time.', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 10000
            });


        })
        .catch((error) => {
            let errorMessage = '';
            if (error.response && error.response.data && error.response.data.message === 'Insufficient credits') {
                errorMessage = 'Insufficient credits. Upgrade to premium for more features.';
            } else {
                errorMessage = 'An error occurred while processing your request. Please try again later.';
            }

            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        });
};


const isSubmitDisabled = computed(() => {
    return (
        domainValidationError.value ||
        searchEngineError.value ||
        deviceTypeError.value ||
        languageError.value ||
        countryError.value ||
        formData.keyword.length === 0
    );
});

const languages = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'eu', name: 'Basque' },
    { code: 'be', name: 'Belarusian' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ceb', name: 'Cebuano' },
    { code: 'ny', name: 'Chichewa' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'co', name: 'Corsican' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'nl', name: 'Dutch' },
    { code: 'en', name: 'English' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'et', name: 'Estonian' },
    { code: 'tl', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fy', name: 'Frisian' },
    { code: 'gl', name: 'Galician' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'haw', name: 'Hawaiian' },
    { code: 'iw', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hmn', name: 'Hmong' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'ig', name: 'Igbo' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'jw', name: 'Javanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'km', name: 'Khmer' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'ko', name: 'Korean' },
    { code: 'ku', name: 'Kurdish (Kurmanji)' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'lo', name: 'Lao' },
    { code: 'la', name: 'Latin' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mi', name: 'Maori' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'my', name: 'Myanmar (Burmese)' },
    { code: 'ne', name: 'Nepali' },
    { code: 'no', name: 'Norwegian' },
    { code: 'or', name: 'Odia (Oriya)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'fa', name: 'Persian' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sm', name: 'Samoan' },
    { code: 'gd', name: 'Scots Gaelic' },
    { code: 'sr', name: 'Serbian' },
    { code: 'st', name: 'Sesotho' },
    { code: 'sn', name: 'Shona' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'tg', name: 'Tajik' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'tr', name: 'Turkish' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'ug', name: 'Uyghur' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'zu', name: 'Zulu' }
];

const searchEngine = ref([
    { id: 1, name: 'Google' },
    { id: 2, name: 'Yahoo' },
    { id: 3, name: 'Bing' }
]);

const deviceType = ref([
    { id: 1, name: 'Desktop' },
    { id: 2, name: 'Tablet' },
    { id: 3, name: 'Mobile' }
]);

const pageSize = 20;
const currentPage = ref(1);
const searchQuery = ref('');
const showResults = ref(false);

const searchResults = computed(() => {
    if (!searchQuery.value.trim()) {
        return [];
    } else {
        return filteredLocations.value.filter(location =>
            location.location_name.toLowerCase().includes(searchQuery.value.trim().toLowerCase())
        );
    }
});

const onChange = () => {
    showResults.value = true;
};

const selectResult = (result) => {
    searchQuery.value = result.location_name;
    showResults.value = false;
    Object.assign(stateData, result);

};

const onCountryChange = async () => {
    currentPage.value = 1;
    await fetchCityStateData();
};

const fetchCityStateData = async () => {
    try {
        const response = await countryService.getCityAndState(formData.country.country_iso_code, currentPage.value, pageSize);
        if (currentPage.value === 1) {
            cityStateData.value = response;
        } else {
            cityStateData.value = [...cityStateData.value, ...response];
        }
    } catch (error) {
        console.error('Error fetching city data:', error);
    }
};

const visibleLocations = computed(() => {
    if (cityStateData.value) {
        return cityStateData.value.slice(0, currentPage.value * pageSize);
    } else {
        return [];
    }
});

const filteredLocations = computed(() => {
    if (!searchQuery.value) {
        return visibleLocations.value;
    } else {
        const query = searchQuery.value.toLowerCase();
        return cityStateData.value.filter(location => {
            return location.location_name.toLowerCase().includes(query);
        });
    }
});

const userData = ref(null)

onMounted(() => {
    countryService.getCountries().then((data) => {
        regionList.value = data.sort((a, b) => a.location_name.localeCompare(b.location_name));
    });
    const data = localStorage.getItem('userData');
    userData.value = JSON.parse(data);

});

const resetConfirm = () => {
    if (confirm("Are you sure you want to reset?")) {
        formData.random_id = generateRandomId();
        formData.type = "";
        formData.deviceType = "";
        formData.language = "";
        formData.domainUrl = "";
        formData.keyword = [];
        formData.competitor = [];
        formData.country = null;
        formData.state_city = null;
        formData.location_name = null;
        formData.option_location = null;
    }
};


</script>


<style>
.competitor-item {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #495057;
}

.competitor-item .competitor-name {
    margin-right: 5px;
}

.remove-btn {
    background-color: transparent;
    border: none;
    color: #dc3545;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s;
}

.remove-btn:hover {
    color: #b52b3a;
}

.disabled-section {
    pointer-events: none;
    opacity: 0.6;
}
</style>