<template>
    <loader :active="isLoading" message="Please wait..." />

    <div class="view-serp-page-cont">
        <div class="view-serp-table-cont">
            <div class="view-serp-table-box">
                <div class="box-top box-top-with-filter-new justify-content-between">
                    <div class="table-top-left-info d-flex flex-wrap row-gap-3">
                        <p class="info-p mb-0">Total Search: <span>{{ total }}</span></p>
                        <p class="info-p mb-0">Total Keywordes: <span>{{ totalKeywords }}</span></p>
                    </div>
                    <div class="table-top-bar">
                        <div class="top-left">
                            <div class="form-group search-group">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchQuery"
                                    @input="serpsList(1, true)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="data-table-cont">
                        <div id="example_wrapper" class="dataTables_wrapper">
                            <div class="row dt-row">
                                <div class="col-sm-12">
                                    <div class="table-responsive table-container table-view-serp"
                                        @scroll="handleScroll">
                                        <table id="example" class="data-table table table-border-none">
                                            <thead class="sticky-top">
                                                <tr>
                                                    <th class="no-wrap"></th>
                                                    <th class="no-wrap">ID</th>
                                                    <th class="no-wrap text-start">Domain</th>
                                                    <th class="no-wrap text-start">Location</th>
                                                    <th class="no-wrap text-start">Keywords</th>
                                                    <th class="no-wrap text-start">Device</th>
                                                    <th class="no-wrap text-start">Search Engine</th>
                                                    <th class="no-wrap text-start">Date</th>
                                                    <th class="no-wrap">Refresh</th>
                                                    <th class="no-wrap">Export</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(item, index) in allData">
                                                    <tr
                                                        :class="{ 'odd': index % 2 === 0, 'even': index % 2 !== 0, 'highlight': index === 0 && highlighted, 'has-child': selectedRow === index }">
                                                        <td class="no-wrap">
                                                            <a class="toggleChild table-toggle-btn"
                                                                :class="{ 'active': selectedRow === index }"
                                                                @click="toggleItem(index)" title="Toggle">
                                                                <img class="icon-arrow"
                                                                    src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-table-toggle-arrow.svg"
                                                                    alt="Icon Table Toggle Arrow" />
                                                            </a>
                                                        </td>
                                                        <td class="no-wrap">{{ item.id }}</td>
                                                        <td class="no-wrap text-start">{{ item.url ?? 'N/A' }}</td>
                                                        <td class="no-wrap text-start">{{ item.location ?? 'N/A' }}</td>
                                                        <td class="no-wrap text-start">{{ item.keywords_count }}</td>
                                                        <td class="no-wrap text-start">Web</td>
                                                        <td class="no-wrap text-start">Google</td>
                                                        <td class="no-wrap text-start responsive-wrap">
                                                            {{ item.date.split('-').reverse().join('-')
                                                            }} <br> {{
                                                                item.time }}
                                                        </td>
                                                        <td class="no-wrap text-center">
                                                            <a class="refresh-icon-cont" @click="refreshItem(item)"
                                                                title="Refresh">
                                                                <img class="icon-refresh"
                                                                    src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-refresh-data-table-new.svg"
                                                                    alt="Refresh Icon" />
                                                            </a>
                                                        </td>
                                                        <td class="no-wrap text-center action-buttons"
                                                            style="white-space: nowrap;">

                                                            <a class="excel-icon-cont" @click="exportToExcel(item)"
                                                                title="Export to Excel">
                                                                <img class="icon-excel"
                                                                    src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-excel-new.svg"
                                                                    alt="Excel Export Icon" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="selectedRow === index">
                                                        <td class="td-additional-info" :colspan="10">
                                                            <div class="additional-info-cont">

                                                                <div class="info-body">
                                                                    <table
                                                                        class="table-additional-info table table-border-none">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="text-start">Keyword</th>
                                                                                <th class="text-start">Ranked Url</th>
                                                                                <th class="text-start">Your Rank</th>
                                                                                <th class="text-start text-primary-new"
                                                                                    v-if="item.items[0].competitor1?.name">
                                                                                    {{ item.items[0].competitor1.name }}
                                                                                </th>
                                                                                <th class="text-start text-primary-new"
                                                                                    v-if="item.items[0].competitor2?.name">
                                                                                    {{ item.items[0].competitor2.name }}
                                                                                </th>
                                                                                <th class="text-start text-primary-new"
                                                                                    v-if="item.items[0].competitor3?.name">
                                                                                    {{ item.items[0].competitor3.name }}
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(keywordItem, keywordIndex) in item.items"
                                                                                :key="'keyword-' + keywordIndex"
                                                                                :class="{ 'odd': keywordIndex % 2 === 0, 'even': keywordIndex % 2 !== 0 }">
                                                                                <td class="text-start no-wrap">{{
                                                                                    keywordItem.keyword }}</td>
                                                                                <td class="text-start">
                                                                                    <a class="keyword-item-url"
                                                                                        :href="keywordItem.url"
                                                                                        target="_blank">
                                                                                        {{ keywordItem.url || 'N/A' }}
                                                                                    </a>
                                                                                </td>
                                                                                <td class="text-start">
                                                                                    <span class="rank">{{
                                                                                        keywordItem.rank }}</span>
                                                                                </td>
                                                                                <!-- Competitor 1 -->
                                                                                <td class="text-start"
                                                                                    v-if="keywordItem.competitor1?.name">
                                                                                    <span class="rank">
                                                                                        {{ keywordItem.competitor1.rank
                                                                                            || 'N/A' }}
                                                                                        <!--
                    <span v-if="isCompetitorRelevant(keywordItem, 'competitor1')">
                      <img class="icon-up-arrow-green" v-if="shouldShowGreenArrow(keywordItem, 'competitor1')"
                        src="/images/icon-up-arrow-green-data-table-new.svg" alt="Up Arrow Icon" />
                      <img class="icon-down-arrow-red" v-else
                        src="/images/icon-down-arrow-red-data-table-new.svg" alt="Down Arrow Icon" />
                    </span>
                    -->
                                                                                    </span>
                                                                                </td>
                                                                                <!-- Competitor 2 -->
                                                                                <td class="text-start"
                                                                                    v-if="keywordItem.competitor2?.name">
                                                                                    <span class="rank">
                                                                                        {{ keywordItem.competitor2.rank
                                                                                            || 'N/A' }}
                                                                                        <!--
                    <span v-if="isCompetitorRelevant(keywordItem, 'competitor2')">
                      <img class="icon-up-arrow-green" v-if="shouldShowGreenArrow(keywordItem, 'competitor2')"
                        src="/images/icon-up-arrow-green-data-table-new.svg" alt="Up Arrow Icon" />
                      <img class="icon-down-arrow-red" v-else
                        src="/images/icon-down-arrow-red-data-table-new.svg" alt="Down Arrow Icon" />
                    </span>
                    -->
                                                                                    </span>
                                                                                </td>
                                                                                <!-- Competitor 3 -->
                                                                                <td class="text-start"
                                                                                    v-if="keywordItem.competitor3?.name">
                                                                                    <span class="rank">
                                                                                        {{ keywordItem.competitor3.rank
                                                                                            || 'N/A' }}
                                                                                        <!--
                    <span v-if="isCompetitorRelevant(keywordItem, 'competitor3')">
                      <img class="icon-up-arrow-green" v-if="shouldShowGreenArrow(keywordItem, 'competitor3')"
                        src="/images/icon-up-arrow-green-data-table-new.svg" alt="Up Arrow Icon" />
                      <img class="icon-down-arrow-red" v-else
                        src="/images/icon-down-arrow-red-data-table-new.svg" alt="Down Arrow Icon" />
                    </span>
                    -->
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>

                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import EventBus from '@/EventBus';
import loader from '../../Loader.vue';

const selectedRow = ref(null);
const searchQuery = ref('');
const currentPage = ref(1);
const lastPage = ref(1);
const isLoading = ref(false);
const total = ref(0);
const totalKeywords = ref(0);
const allData = ref([]);

const serpsList = async (page, isSearch = false, isrefresh = false) => {
    isLoading.value = true;

    try {
        const data = await axiosIns.get(`serp/list-serps?&search=${searchQuery.value}&per_page=30&page=${page}`);

        if (isSearch || isrefresh) {
            allData.value = [];
            allData.value = data.data.data;
        } else {
            allData.value.push(...data.data.data);
        }

        currentPage.value = Number(data.data.pagination.current_page);
        lastPage.value = Number(data.data.pagination.last_page);
        total.value = data.data.pagination.total;
        totalKeywords.value = data.data.pagination.total_keywords;

        localStorage.setItem('userData', JSON.stringify(data.data.userData));

        EventBus.$emit('loadCredits', true);

        if (data.data.isItemNull) {
            toast.info('If any keywords are missing, it will take 3 to 5 minutes to retrieve all the keyword ranks. Please check back in a while.', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 10000,
            });

            setTimeout(() => {
                serpsList(page, false, true);
            }, 60000); // 1 minute timeout
        }



        isLoading.value = false;

    } catch (error) {
        console.error('Error fetching serps data:', error);

        toast.error('Failed to fetch serps data. Please try again later', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        isLoading.value = false;
    }
};


const exportToExcel = async (data) => {
    try {
        const response = await axiosIns.post('/excel/generate-serps-with-id', data, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast.success('Excel Generated!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 2000
        });
    } catch (error) {
        toast.error('Error while generating excel!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 2000
        });
    }
}


const toggleItem = (index) => {
    selectedRow.value = selectedRow.value === index ? null : index;
};

const isCompetitorRelevant = (keywordItem, competitorKey) => {
    const competitorRank = keywordItem[competitorKey]?.rank;
    return competitorRank !== 'N/A' && competitorRank !== null;
};

const shouldShowGreenArrow = (keywordItem, competitorKey) => {
    const currentRank = parseRank(keywordItem.rank);
    const competitorRank = parseRank(keywordItem[competitorKey]?.rank);

    return competitorRank && currentRank < competitorRank;
};

const parseRank = (rank) => {
    if (rank === 'N/A' || rank === null || rank === undefined) return null;
    return Number(rank);
};

const refreshItem = async (data) => {
    const currentDate = new Date();
    const lastRefreshDate = new Date(data.date + ' ' + data.time);

    const timeDifference = currentDate - lastRefreshDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);


    if (hoursDifference <= 4) {
        const shouldRefresh = window.confirm(
            "Google's index updates infrequently, so daily checks are advised. Click 'Confirm' to proceed with a new search anyway"
        );

        if (!shouldRefresh) {
            return false;
        }
    }

    try {
        await axiosIns.post('/serp/refresh-serp', data);
        toast.success('SERP item refreshed successfully. Updates may take up to 5 minutes to reflect. Please check again after 5 minutes if needed.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 10000
        });

        setTimeout(() => {
            serpsList(1, false , true);
        }, 50000);

    } catch (error) {
        if (error.response) {
            toast.error(`Error Refreshed Serp: ${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 2000
            });
        } else {
            toast.error('Error Refreshed Serp', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 2000
            });
        }
        console.error('Error fetching user data:', error);
    }

};

const handleScroll = (event) => {
    const container = event.target;

    // Check if the user is scrolling vertically
    if (container.scrollHeight > container.clientHeight) {
        if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
            loadMore();
        }
    }
};


const loadMore = () => {
    console.log("Loading more keywords...", isLoading.value);

    setTimeout(() => {
        currentPage.value++;
        serpsList(currentPage.value);
    }, 1000);
};

watch(searchQuery, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        currentPage.value = 1;
        serpsList(currentPage.value)
    }
});

onMounted(() => {
    EventBus.$on('loadSerps', data => {
        if (data) {
            serpsList(currentPage.value);
        }
    })
    serpsList(currentPage.value);
});


</script>

<style>
.table-container {
    max-height: 1500px;
    overflow-y: auto;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>