<template>

    <loader :active="loading" message="Please wait..." />

   

    <div class="admin-body">
        <div class="admin-body-box">
            <div class="admin-body-bottom">
                <div class="table-top">
                    <div class="header-top-btn-cont">
                        <a class="admin-common-btn" @click="addPermission" data-bs-toggle="modal" data-bs-target="#modal-add-role">Add Permission</a>
                    </div>
                </div>
                <div class="data-table-cont">
                    <!-- <div class="table-responsive maxheight64vh"> -->
                    <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div class="row dt-row">
                            <div class="col-sm-12">
                                <div class="table-responsive table-manage-permissions">
                                    <table id="example" class="data-table table table-striped table-border-none spacing5 dataTable no-footer" aria-describedby="example_info">
                                        <thead class="sticky-top">
                                            <tr>
                                                <th class="no-wrap text-start">Name</th>
                                                <th class="no-wrap text-start">Description</th>
                                                <th class="no-wrap text-end">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(permission, index) in permissions" :key="index">
                                                <td class="no-wrap text-start sorting_1">{{ permission.name }}</td>
                                                <td class="no-wrap text-start sorting_1">{{ permission.description }}</td>
                                                <td class="no-wrap text-end">
                                                    <div class="action-icon-cont justify-content-end">
                                                        <a class="action-icon icon-edit" 
                                                            data-bs-toggle="modal" data-bs-target="#modal-add-role"
                                                            @click="editPermission(permission)">
                                                            <img class="icon-img" src="images/icon-edit-user.svg"
                                                                alt="Edit User">
                                                        </a>
                                                        <a class="action-icon icon-delete"
                                                            @click="deletetPermission(permission)">
                                                            <img class="icon-img" src="images/icon-delete.svg"
                                                                alt="Delete">
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD USER - START -->
    <div id="modal-add-role" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Add Permission</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Add Permission</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Name:</label>
                                <input class="form-control" type="text" placeholder="Enter Permission Name"
                                    v-model="permission.name" required>
                                <!-- <span class="required-alert">Enter First Name is required.</span> -->
                            </div>
                        </div>

                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Description:</label>
                                <textarea class="form-control" placeholder="Enter Permission Description"
                                    v-model="permission.description" required></textarea>
                                <!-- <span class="required-alert">Enter Role Description is required.</span> -->
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <Button class="admin-common-btn btn-bordered btn btn-reset" type="reset">Reset</Button>
                        <Button class="admin-common-btn btn btn-primary btn-next" type="button" data-bs-dismiss="modal"
                            @click="addOrUpdatePermission">
                            {{ isUpdateBtn ? 'Update' : 'Submit' }}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD USER - END -->

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAbility } from '@casl/vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import loader from '../Loader.vue';

const { can } = useAbility();
const permissions = ref([]);
const isUpdateBtn = ref(false);
const permission = ref({});
const loading = ref(false)
async function fetchPermissions() {
    loading.value = true
    try {
        const response = await axiosIns.get('/permissions');
        loading.value = false
        permissions.value = response.data.data;
    } catch (error) {
        console.error('Error fetching permissions:', error);
    }
}

const addPermission = () => {
    permission.value = {}
    isUpdateBtn.value = false
}

const permissionId = ref(null)

const editPermission = async (data) => {
    isUpdateBtn.value = true;
    permissionId.value = data.id;
    permission.value.name = data.name
    permission.value.description = data.description

}

const createPermission = async () => {

    try {
        await axiosIns.post('permissions', { name: permission.value.name, description: permission.value.description });

        toast.success('Permission Created', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        setTimeout(async () => {
            await fetchPermissions();
        }, 1000);
    } catch (error) {
        console.error('Error creating permission:', error.message);
    }
};

const updatePermission = async () => {
    try {
        await axiosIns.put(`/permissions/${permissionId.value}`, { name: permission.value.name, description: permission.value.description });

        toast.success('Permission Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        setTimeout(async () => {
            await fetchPermissions();
        }, 1000);
    } catch (error) {
        console.error('Error updating permission:', error.message);
    }
};



const deletetPermission = async (permission) => {
    try {
        const confirmed = confirm(`Are you sure you want to delete Permission?`);
        if (confirmed) {
            await axiosIns.delete(`/permissions/${permission.id}`);
            permissions.value = permissions.value.filter((u) => u.id !== permission.id);
            toast.success(`Permission deleted!`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        }
    } catch (error) {
        console.error('Error Deleting Permission:', error);
        toast.error('Failed to delete permission. Please try again later.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    }
};

const addOrUpdatePermission = () => {
    if (isUpdateBtn.value) {
        updatePermission();
    } else {
        createPermission();
    }
};

onMounted(async () => {
    try {
        loading.value = true 
        const data = await axiosIns.get('permissions');
        permissions.value = data.data.data;
        loading.value = false 
    } catch (error) {
        console.error('Error:', error.message);
    }

});


</script>