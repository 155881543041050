<template>
  <loader :active="loading" message="Please wait..." />

  <aside id="nav-bar" class="admin-sidebar l-navbar show">
    <div class="sidebar-box">
      <div class="brand-logo-cont">
        <router-link to="/" class="brand-logo">
          <img class="logo img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/logo.png"
            alt="SerpPlus" />
          <img class="logo-icon img-fluid"
            src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/logo-icon.png" alt="SerpPlus" />
        </router-link>
    
      </div>
      <nav class="admin-left-nav flex-shrink-0">
        <ul class="nav-ul">


          <li :class="{ active: isRouteGroupActive(['/create-serp', '/view-serps']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse"
              data-bs-target="#serp-management" aria-expanded="false">
              <img class="nav-icon" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar2.svg" alt="Icon SERP Management">
              <span class="nav-text">Serp Checker</span>
            </div>
            <div id="serp-management" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('ADD_SERP')" :class="{ active: isRouteActive('/create-serp') }">
                  <router-link to="/create-serp" title="Check Serp">
                    <span class="nav-text">Check Serp</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PROJECT')" :class="{ active: isRouteActive('/view-serps') }">
                  <router-link to="/view-serps" title="Serp Listing">
                    <span class="nav-text">Serp Listing</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>


          <li :class="{ active: isRouteGroupActive(['/create-serp-project', '/list-serp-project']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#serp-projects"
              aria-expanded="false">
              <img class="nav-icon" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar7.svg" alt="Icon Serp Projects">
              <span class="nav-text">Serp Projects</span>
            </div>
            <div id="serp-projects" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('ADD_SERP')" :class="{ active: isRouteActive('/create-serp-project') }">
                  <router-link title="New Project" to="/create-serp-project">
                    <span class="nav-text">New Project</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PROJECT')" :class="{ active: isRouteActive('/list-serp-project') }">
                  <router-link to="/list-serp-project" title="Projects Listing">
                    <span class="nav-text">Projects Listing</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>



          <li :class="{ active: isRouteGroupActive(['/domain-list', '/domain-projects-list']) }" v-if="can('ADD_SERP')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse"
              data-bs-target="#domain-checker" aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar3.svg"
                alt="Icon Domain Checker">
              <span class="nav-text">Domain Checker</span>
            </div>
            <div id="domain-checker" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/domain-list') }" title="Check Domain Ranking">
                  <router-link to="/domain-list">
                    <span class="nav-text">Check Domain Ranking</span>
                  </router-link>
                </li>
                <li :class="{ active: isRouteActive('/domain-projects-list') }" title="Domain Projects">
                  <router-link to="/domain-projects-list">
                    <span class="nav-text">Domain Projects</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li :class="{ active: isRouteGroupActive(['/backlink-overview', '/backlinks-projects-list']) }"
            v-if="can('VIEW_CUSTOMER_PLANS')">
            <div class="nav-main-cat btn btn-toggle" data-bs-toggle="collapse" data-bs-target="#backlink-module"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar4.svg"
                alt="Icon Backlink Module">
              <span class="nav-text">Backlink Module</span>
            </div>
            <div id="backlink-module" class="nav-sub-cat collapse show">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/backlink-overview') }" title="Backlink Overview">
                  <router-link to="/backlink-overview">
                    <span class="nav-text">Backlink Overview</span>
                  </router-link>
                </li>
                <li :class="{ active: isRouteActive('/backlinks-projects-list') }" title="Backlinks Projects">
                  <router-link to="/backlinks-projects-list">
                    <span class="nav-text">Backlinks Projects</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>


          <li v-if="can('VIEW_USERS')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#authorization"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-serp-checker.svg"
                alt="Icon Nav">
              <span class="nav-text">Authorization</span>
              <!-- <span class="cat-title">Authorization</span> -->
            </div>
            <div id="authorization" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_USERS')" :class="{ active: isRouteActive('/user-list') }">
                  <router-link to="/user-list" title="User">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">👤</span> -->
                    <span class="nav-text">User</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_ROLE')" :class="{ active: isRouteActive('/role-list') }">
                  <router-link to="/role-list" title="Roles">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">🔐</span> -->
                    <span class="nav-text">Roles</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PERMISSION')" :class="{ active: isRouteActive('/permission-list') }">
                  <router-link to="/permission-list" title="Permissions">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">🔒</span> -->
                    <span class="nav-text">Permissions</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMER')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#customer"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-serp-checker.svg"
                alt="Icon Nav">
              <span class="nav-text">Customers</span>
              <!-- <span class="cat-title">Customers</span> -->
            </div>
            <div id="customer" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_CUSTOMER')" :class="{ active: isRouteActive('/customer-list') }">
                  <router-link to="/customer-list" title="Customers">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">👥</span> -->
                    <span class="nav-text">Customers</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_FAQ')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#plans"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-serp-checker.svg"
                alt="Icon Nav">
              <span class="nav-text">Plans</span>
              <!-- <span class="cat-title">Plans</span> -->
            </div>
            <div id="plans" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/plan-list') }">
                  <router-link to="/plan-list" title="Plans">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">📋</span> -->
                    <span class="nav-text">Plans</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_FAQ')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#faq"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-serp-checker.svg"
                alt="Icon Nav">
              <span class="nav-text">Faq</span>
              <!-- <span class="cat-title">Faq</span> -->
            </div>
            <div id="faq" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_FAQ')" :class="{ active: isRouteActive('/faq-category-list') }">
                  <router-link to="/faq-category-list" title="Category">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">📚</span> -->
                    <span class="nav-text">Category</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_FAQ')" :class="{ active: isRouteActive('/faq-questions-list') }">
                  <router-link to="/faq-questions-list" title="Question">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">❓</span> -->
                    <span class="nav-text">Question</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMER_PLANS')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse"
              data-bs-target="#plan-subscription" aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar5.svg"
                alt="Icon Plan Subscription">
              <span class="nav-text">Plan Subscription</span>
              <!-- <span class="cat-title">Plan Subscription</span> -->
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/purchase-list') }">
                  <router-link to="/purchase-list" title="Plan">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">📅</span> -->
                    <span class="nav-text">Plan</span>
                  </router-link>
                </li>
                <li :class="{ active: isRouteActive('/credits-list') }">
                  <router-link to="/credits-list" title="Credits History">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">💳</span> -->
                    <span class="nav-text">Credits History</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMER_PLANS')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#support"
              aria-expanded="false">
              <img class="nav-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-sidebar6.svg"
                alt="Icon Support">
              <span class="nav-text">Support</span>
              <!-- <span class="cat-title">Support</span> -->
            </div>
            <div id="support" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/faq-customer') }">
                  <router-link to="/faq-customer" title="FAQ">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">❓</span> -->
                    <span class="nav-text">FAQ</span>
                  </router-link>
                </li>
                <li>
                  <a href="mailto:info@pluspromotions.co.uk" title="Mail us">
                    <!-- <span class="subnav-icon"></span> -->
                    <!-- <span class="nav-icon">✉️</span> -->
                    <span class="nav-text">Mail us</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <!-- <li v-if="can('VIEW_ROLE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#app-setting"
              aria-expanded="false">
              <img class="nav-icon" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-serp-checker.svg" alt="Icon Nav">
              <span class="nav-text">Settings</span>
            </div>
            <div id="app-setting" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li :class="{ active: isRouteActive('/profile-setting') }">
                  <router-link to="/profile-setting" title="Profile">
                    <span class="subnav-icon"></span>
                    <span class="nav-text">Profile</span>
                  </router-link>
                </li>
                <li :class="{ active: isRouteActive('/change-password') }">
                  <router-link to="/change-password" title="Change Password">
                    <span class="subnav-icon"></span>
                    <span class="nav-text">Change Password</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li> -->
        </ul>
      </nav>

      <div class="upgradeto-pro-box" v-if="userType === 1">
        <div class="box-cont">
          <!-- <img class="logo-upgrade-to-pro" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/logo-upgrade-to-pro-new.svg" alt="Upgrade To Pro"> -->
          <h4 class="title-utp">UPGRADE TO PRO</h4>
          <div class="text-utp">
            <p>Get our all advanced tools, deeper insights, and more credits.</p>
          </div>
          <a class="btn-gpn" data-bs-toggle="modal" data-bs-target="#modal-add-project-pricing-plan">Upgrade Now</a>
        </div>
      </div>

    </div>
  </aside>

  <!-- MODAL - ADD PROJECT - PRICING PLAN - START -->
  <div id="modal-add-project-pricing-plan" class="common-modal-cont modal fade modal-add-project-pricing-plan"
    tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-1120 modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title mx-auto">
              <span class="mod-title">Pricing Plan</span>

            </h5>
            <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-popup-close.svg"
                alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <p class="top-msg">Checkout our best SERP + Plans now – very affordable services with accurate results.
            </p>
            <div class="modal-toggle-switch-cont switch-left-right-label">
              <div class="form-check form-switch switch-new">
                <input type="radio" class="btn-check" name="billingOptions" id="billMonthly" autocomplete="off"
                  v-model="isMonthly" :value="true">
                <label class="btn btn-secondary switch-btn btn-left" for="billMonthly">Bill Monthly</label>
                <input type="radio" class="btn-check" name="billingOptions" id="billAnnually" autocomplete="off"
                  v-model="isMonthly" :value="false">
                <label class="btn btn-secondary switch-btn btn-right" for="billAnnually">Bill Annually</label>
              </div>
            </div>
            <div class="plan-cont">
              <div class="row plan-box-row">
                <div class="plan-box-col col-xs-12 col-md-4 col-lg-4" v-for="plan in plans" :key="plan.id">
                  <div class="plan-box" :class="{ 'highlight': activeSub && activeSub.name === plan.name }">
                    <div class="plan-price">£{{ getPrice(plan) }} <sub>/ {{ billingType }}</sub></div>
                    <h2 class="plan-name">{{ plan.name }}</h2>
                    <ul class="mb-5" style="list-style: none; padding-left: 0;">
                      <li style="font-size: 16px;">💰 {{ getCredits(plan) + " Credits" }}</li>
                      <li style="font-size: 16px;">📋 {{ plan.projects_count + " Projects" }}</li>
                      <b v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled === 1"
                        style="color: red; text-align: center; display: block; margin-top: 20px;">
                        Subscription will be cancelled but still {{ remainingDays }} days remain 🕒
                      </b>
                      <b v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled === null"
                        style="color: green; text-align: center; display: block; margin-top: 20px;">
                        Subscription is active and there are still {{ remainingDays }} days remaining 🕒
                      </b>
                    </ul>
                    <div class="admin-box-btn-cont">
                      <a v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled !== 1"
                        class="admin-common-btn btn-bordered btn-full btn btn-get-started mb-1 mt-4"
                        data-bs-dismiss="modal" aria-label="Close"
                        @click="handleGetStarted(plan, getPrice(plan), plan.id, true)">
                        {{ activeSub.interval == 'month' ? 'Upgrade To Yearly Plan' : 'Downgrade To Monthly Plan' }}
                      </a>

                      <a v-if="activeSub && activeSub.name === plan.name && activeSub.is_cancelled !== 1"
                        class="admin-common-btn  btn-full btn btn-cancel" data-bs-dismiss="modal" aria-label="Close"
                        @click="handleCancel()">Cancel</a>


                      <a v-else class="admin-common-btn btn-bordered btn-full btn btn-get-started"
                        data-bs-dismiss="modal" aria-label="Close"
                        @click="handleGetStarted(plan, getPrice(plan), plan.id, false)">SELECT</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <StripeCheckout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
            :customer-email="email" :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v" />
        </form>
      </div>
    </div>
  </div>
  <!-- MODAL - ADD PROJECT - PRICING PLAN - END -->
  <div id="modal-plan-change" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-690 modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="updateUser">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Change Plan</span>
            </h5>
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-popup-close.svg"
                alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont change-plan-form-cont mb-4">
              <h6>Would you like to start the upgrade subscription now? If yes, press 'Now' to start immediately. If you
                prefer to upgrade after the current period ends, press 'After'. Otherwise, press 'Cancel'.</h6>
            </div>
          </div>
          <div class="modal-footer">
            <button class="admin-common-btn btn-bordered btn btn-after" type="button" data-bs-dismiss="modal"
              @click="upgradeAfter" aria-label="Close">After</button>
            <button class="admin-common-btn btn btn-primary btn-cancel" data-bs-dismiss="modal"
              type="submit">Cancel</button>

            <button class="admin-common-btn btn-bordered btn btn-now" type="button" data-bs-dismiss="modal"
              @click="upgradeNow" aria-label="Close">Now</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useAbility } from '@casl/vue';
import axiosIns from '@/axios';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import EventBus from '@/EventBus';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Loader from '@/views/Loader.vue';
import { FRONT_APP_URL } from '@/Constants';
import { STRIPE_PK } from '@/Constants';

export default {
  components: {
    StripeCheckout,
    Loader,
  },
  data() {
    return {
      userType: null,
      plans: [],
      isMonthly: true,
      publishableKey: `${STRIPE_PK}`,
      lineItems: [],
      loading: false,
      activeSub: {},
      projects_count: null,
      domain_project_count: null,
      remainingDays: null,
      prepareDataForUpgrade: {},
      successURL: `${FRONT_APP_URL}payment-done`,
      cancelURL: `${FRONT_APP_URL}payment-failed`,
    };
  },
  mounted() {
    EventBus.$on('loadCredits', data => {
      if (data) {
        this.loadUser()
      }
    })
    this.fetchPlans()
    EventBus.$on('passDetails', data => {
      if (data) {
        this.activeSub = data;
        const expireDate = this.activeSub.expire_date;
        const currentDate = new Date();
        const remainingTime = new Date(expireDate) - currentDate;
        this.remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

        if (this.activeSub.interval == 'month') {
          this.isMonthly = true
        } else {
          this.isMonthly = false
        }
      }
    })
    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.email = userData?.email;
    // Do something with userData, for example, set it to a reactive property
    //this.userType = userData?.type;

    //this.projects_count = userData.projects_count;
  },
  methods: {
    async loadUser() {
      try {
        const data = await axiosIns.get('/user/show');
        localStorage.setItem('userData', JSON.stringify(data.data.userData));

        this.projects_count = data.data.userData.projects_count;
        this.domain_project_count = data.data.userData.domain_project_count;
        this.userType = data.data.userData.type;
      } catch (error) {
        console.error('Logout failed:', error);

      }
    },
    isRouteActive(route) {
      return this.$route.path === route;
    },
    isRouteGroupActive(routes) {
      return routes.some(route => this.$route.path.startsWith(route));
    },

    async fetchPlans() {
      try {
        const data = await axiosIns.get('plans');
        this.plans = data.data.plans;

        localStorage.setItem('userData', JSON.stringify(data.data.userData));
      } catch (error) {
        console.error('Error:', error.message);
      }
    },
    getPrice(plan) {
      return this.isMonthly ? plan.monthly_price : plan.yearly_price;
    },
    getCredits(plan) {
      return this.isMonthly ? plan.monthly_credits : plan.yearly_credits;
    },
    async handleCancel() {
      if (!confirm("Are you sure you want to cancel this subscription?")) {
        return;
      }
      this.loading = true
      try {
        const data = await axiosIns.post(`/plans/cancel-subscription/${this.activeSub.sub_id}`);
        localStorage.setItem('userData', JSON.stringify(data.data.userData));
        this.loading = false
        toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        location.reload();
      } catch (error) {
        this.loading = false

        console.error('Error cancelling subscription:', error);
        toast.error("An error occurred while cancelling the subscription. Please try again later.");
      }
    },
    async handleGetStarted(data, price, planId, samePlanChange) {
      const { plans_stripe: plans } = data;
      // Filter monthly and yearly plans based on the value of isMonthly
      var filteredPlans = this.isMonthly
        ? plans.filter(plan => plan.interval === 'month')
        : plans.filter(plan => plan.interval === 'year');

      if (samePlanChange) {
        if (this.activeSub.interval == 'month') {
          filteredPlans = plans.filter(plan => plan.interval === 'year');
        } else {
          filteredPlans = plans.filter(plan => plan.interval === 'month');
        }

        this.prepareDataForUpgrade = {
          subscriptionId: this.activeSub.sub_id,
          priceId: filteredPlans[0].price,
          planId: planId,
          prorationBehavior: false,
          isJob: false,
          update_on: this.activeSub.expire_date,
        }

        const button = this.$refs.changePlanButton;
        button.click();

        return false
      }

      if (filteredPlans.length > 0) {
        const selectedPlan = filteredPlans[0];

        const currentPrice = parseFloat(this.activeSub.price);
        // Check if the selected plan price is less than the current price (downgrade)
        if (parseFloat(price) < currentPrice) {
          if (confirm('Are you sure you want to downgrade your plan?')) {
            this.loading = true
            try {
              const data = await axiosIns.post(`plans/upgrade-downgrade-subscription`, {
                subscriptionId: this.activeSub.sub_id,
                priceId: selectedPlan.price,
                planId: planId,
                prorationBehavior: true,
                isJob: false,
                update_on: this.activeSub.expire_date,
              }, {
                timeout: 15000 // Timeout set to 15 seconds (15000 milliseconds)
              });
              this.loading = false
              toast.success("Plan upgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
              });
              setTimeout(() => {
                window.location.href = '/check-serp';
              }, 6000);
            } catch (error) {
              this.loading = false
              toast.success("Plan downgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
              });
              setTimeout(() => {
                window.location.href = '/check-serp';
              }, 6000);
              console.error(error);
            }
          }
        } else if (parseFloat(price) > currentPrice) {

          this.prepareDataForUpgrade = {
            subscriptionId: this.activeSub.sub_id,
            priceId: selectedPlan.price,
            planId: planId,
            prorationBehavior: false,
            isJob: false,
            update_on: this.activeSub.expire_date,
          }

          const button = this.$refs.changePlanButton;
          button.click();
        }
        else {
          const confirmation = confirm("You will now be redirected to our payment gateway to complete your transaction?");

          if (confirmation) {
            this.lineItems = [{ price: selectedPlan.price, quantity: 1 }];
            this.$refs.checkoutRef.redirectToCheckout();
          } else {
            console.log("Checkout process canceled by user.");
          }
        }
      } else {
        console.error(this.isMonthly ? 'No monthly plans found.' : 'No yearly plans found.');
      }
      return false;
    },
    async upgradeNow() {
      this.loading = true;
      try {
        await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
          timeout: 15000
        });
        toast.success("Plan upgraded successfully. Changes take effect immediately.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        setTimeout(() => {
          window.location.href = '/check-serp';
        }, 6000);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async upgradeAfter() {
      this.loading = true;
      this.prepareDataForUpgrade['isJob'] = false;
      try {
        await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
          timeout: 15000
        });
        this.loading = false;

        toast.success("Plan upgrade scheduled successfully. Your subscription will be updated at the end of the current plan period.", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000
        });
        setTimeout(() => {
          window.location.href = '/check-serp';
        }, 6000);
      } catch (error) {
        this.loading = false;
      }

    }

  },
  computed: {
    can() {
      return useAbility().can;
    },
    billingType() {
      return this.isMonthly ? 'Monthly' : 'Yearly';
    }
  }
};
</script>

<style scoped>
/* Style for unchecked switch */
.form-check-input {
  background-color: #007bff !important;
  /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
  background-color: #007bff !important;
  /* Change to your desired color for checked state */
}

.highlight {
  background-color: #e7f2ff;
  /* Light blue background color */
  border: 2px solid #45C9F4;
  /* Border color */
  border-radius: 5px;
  /* Optional: Adjust border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  /* Optional: Add a shadow effect */
}


.btn-cancel {
  color: #dc3545;
  /* Red text color */
  background-color: transparent;
  /* Transparent background color */
  border-color: #dc3545;
  /* Red border color */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.btn-cancel:hover {
  background-color: #dc3545;
  /* Red background color on hover */
  color: #fff;
  /* White text color on hover */
}

.badge {
  background-color: #007bff;
  /* Badge background color */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Border radius to round the badge */
  padding: 3px 6px;
  /* Padding around the text inside the badge */
  font-size: 12px;
  /* Font size of the text inside the badge */
  margin-left: 5px;
  /* Adjust this value to position the badge as desired */
}

.badge-red {
  background-color: red;
  /* Badge background color */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Border radius to round the badge */
  padding: 3px 6px;
  /* Padding around the text inside the badge */
  font-size: 12px;
  /* Font size of the text inside the badge */
  margin-left: 5px;
  /* Adjust this value to position the badge as desired */
}
</style>