<template>

  <div class="admin-header-bottom">
    <nav class="admin-breadcrumb" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/"
            style="color: var(--color-admin-breadcrumb);font-weight: 500;text-decoration: none;">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">View Customer</li>
      </ol>
    </nav>

  </div>

  <div class="admin-body no-bg-space">
    <div class="admin-body-box">
      <div class="admin-box-row row">
        <div class="admin-box-col col-xs-12 col-md-12 col-lg-12 box-col-full">
          <div class="admin-box box-user-project-chart">
            <div class="admin-box-title with-chart-info-filter">
              <div class="title-cont">
                <!-- Months Filter UI -->
                <select id="monthsFilter" v-model="selectedMonth" @change="fetchCustomerCredits()">
                  <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
                </select>
              </div>
            </div>
            <CanvasJSChart :options="getBarChartOptions()" />
          </div>
        </div>
      </div>
      <div class="admin-box-row row">
        <div class="admin-box-col col-xs-12 col-md-12 col-lg-12 box-col-left">
          <div class="admin-box box-user-details">
            <div class="admin-box-left-right">
              <div class="admin-box-left">
                <h2 class="admin-box-title">Cameron Williamson</h2>
                <div class="admin-box-btn-cont full-width-btn">
                  <a class="admin-common-btn btn-full btn btn-primary btn-edit" data-bs-toggle="modal"
                    data-bs-target="#modal-edit-customer">Edit</a>
                  <a class="admin-common-btn btn-full btn btn-suspend"
                    :class="['btn-bordered', { 'bordered-red': isSuspended, 'bordered-green': !isSuspended }]"
                    @click="updateSuspendStatus">
                    {{ suspendButtonLabel }}
                  </a>

                </div>

              </div>
              <div class="admin-box-right">
                <div class="admin-box-txt">
                  <div class="user-info">
                    <span class="title">Email :</span>
                    <span class="txt">{{ form.email || 'N/A' }}</span>
                  </div>
                  <div class="user-info">
                    <span class="title">Status :</span>
                    <span
                      :class="{ 'txt': true, 'text-success': form.suspend !== undefined && form.suspend == 0, 'text-danger': form.suspend !== undefined && form.suspend != 0, 'suspended-status': form.suspend !== undefined && form.suspend != 0 }">{{
                  form.suspend === undefined ? 'N/A' : (form.suspend == 0 ? 'Active' : 'Suspended') }}</span>
                  </div>

                  <div class="user-info">
                    <span class="title">Contact :</span>
                    <span class="txt">{{ form.contact || 'N/A' }}</span>
                  </div>
                  <div class="user-info">
                    <span class="title">Language :</span>
                    <span class="txt">{{ form.language || 'N/A' }}</span>
                  </div>
                  <div class="user-info">
                    <span class="title">Credits :</span>
                    <span class="txt">{{ form.credits }} &#128176;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div id="modal-edit-customer" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-490 modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Edit Customer</span>
              <!-- <div class="info-cont tooltip-trigger">
                <div class="custom-tooltip">
                  <div class="tooltip-cont">
                    <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                    <span class="text">Edit Customer</span>
                  </div>
                </div>
              </div> -->
            </h5>
            <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Close Circle">
                  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.6516 8.65165C12.9445 8.35876 12.9445 7.88388 12.6516 7.59099C12.3587 7.2981 11.8839 7.2981 11.591 7.59099L9.99998 9.18198L8.40899 7.59099C8.1161 7.2981 7.64123 7.2981 7.34833 7.59099C7.05544 7.88388 7.05544 8.35876 7.34833 8.65165L8.93932 10.2426L7.34833 11.8336C7.05544 12.1265 7.05544 12.6014 7.34833 12.8943C7.64123 13.1872 8.1161 13.1872 8.40899 12.8943L9.99998 11.3033L11.591 12.8943C11.8839 13.1872 12.3587 13.1872 12.6516 12.8943C12.9445 12.6014 12.9445 12.1265 12.6516 11.8336L11.0606 10.2426L12.6516 8.65165Z"
                    fill="#45C9F4" />
                </g>
              </svg>
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont check-serp-form-cont">
              <div class="form-group">
                <label>Name:</label>
                <input class="form-control" type="text" v-model="form.name" placeholder="Enter Name" required>
                <!-- <span class="required-alert">Enter First Name is required.</span> -->
              </div>
              <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="email" v-model="form.email" placeholder="mayur@example.com">
              </div>
              <div class="form-group">
                <label>Contact:</label>
                <input class="form-control" type="text" placeholder="Enter Contact Number" v-model="form.contact"
                  required>
                <!-- <span class="required-alert">Enter First Name is required.</span> -->
              </div>
              <div class="form-group">
                <label>Credits:</label>
                <input class="form-control" type="number" placeholder="Enter Credits" v-model="form.credits"
                  required>
                <!-- <span class="required-alert">Enter First Name is required.</span> -->
              </div>

              <div class="form-group">
                <label>Serp Projects:</label>
                <input class="form-control" type="number" placeholder="Enter Credits" v-model="form.projects_count"
                  required>
                <!-- <span class="required-alert">Enter First Name is required.</span> -->
              </div>

              <div class="form-group">
                <label>Domain Projects:</label>
                <input class="form-control" type="number" placeholder="Enter Credits" v-model="form.free_domain_count"
                  required>
                <!-- <span class="required-alert">Enter First Name is required.</span> -->
              </div>

              <div class="form-group">
                <label>Language:</label>
                <select class="form-control" v-model="form.language">
                  <option>Select Language</option>
                  <option v-for="language in languages" :key="language.code" :value="language.code">
                    {{ language.name }}
                  </option>
                </select>
              </div>
            </div>



          </div>
          <div class="modal-footer">
            <Button class="admin-common-btn btn-bordered btn btn-reset" type="reset">Reset</Button>
            <Button class="admin-common-btn btn btn-primary btn-next" type="button" @click="updateCustomer"
              data-bs-dismiss="modal">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script setup>
import axiosIns from "@/axios";
import { ref, onMounted, computed, watch } from "vue";
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const router = useRouter();
const credits = ref([]);

const getBarChartOptions = () => {
  return {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Credits Usage"
    },
    axisX: {
      title: "Date",
      labelAngle: -50,
    },
    axisY: {
      title: "Credits",
    },
    data: [{
      type: "column",
      dataPoints: credits.value,
    }]
  };
};

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'ja', name: 'Japanese' },
  { code: 'ko', name: 'Korean' },
  { code: 'zh', name: 'Chinese' }
];

const form = ref({
  id: '',
  name: '',
  email: '',
  contact: '',
  language: '',
  credits: '',
  suspend: '',
  projects_count: '',
  free_domain_count: ''
});

const currentMonthIndex = new Date().getMonth();
const selectedMonth = ref(currentMonthIndex + 1);

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


const isSuspended = ref(false);
const suspendButtonLabel = computed(() => isSuspended.value ? 'Activate' : 'Suspend');

const fetchCustomerData = async () => {
  try {
    const response = await axiosIns.get(`/user/show/${router.currentRoute.value.params.id}`);
    const userData = response.data.userData;
    form.value = {
      id: userData.id,
      name: userData.name || '',
      email: userData.email,
      contact: userData.contact,
      language: userData.language,
      credits: userData.credits,
      suspend: userData.suspend,
      projects_count: userData.projects_count,
      free_domain_count: userData.free_domain_count
    };
    isSuspended.value = userData.suspend
  } catch (error) {
    console.error('Error fetching customer data:', error);
  }
};

const fetchCustomerCredits = async () => {
  const month = selectedMonth.value < 10 ? `0${selectedMonth.value}` : selectedMonth.value;
  const endpoint = `/user/credits-chart/${router.currentRoute.value.params.id}?selectedMonth=${month}`;

  try {
    const response = await axiosIns.get(endpoint);
    credits.value = response.data.history.map(item => ({
      label: item.date_time,
      y: item.keywords_count,
      color: "#45C9F4"
    }));

  } catch (error) {
    console.error('Error fetching credits data:', error);
  }
};

const updateCustomer = async () => {
  const payload = {
    name: form.value.name,
    email: form.value.email,
    contact: form.value.contact,
    language: form.value.language,
    credits: form.value.credits,
    projects_count: form.value.projects_count,
    free_domain_count: form.value.free_domain_count
  };

  try {
    await axiosIns.put(`/profile/update/${form.value.id}`, payload);
    toast.success('Profile Updated', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
    fetchCustomerData(router.currentRoute.value.params.id)
  } catch (error) {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
  }
}

const updateSuspendStatus = async () => {
  const payload = {
    suspend: form.value.suspend == 1 ? 0 : 1
  };

  try {
    await axiosIns.put(`/user/${form.value.id}/suspend`, payload);
    const suspendMessage = form.value.suspend ? 'User activated' : 'User suspended';
    toast.success(suspendMessage, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
    fetchCustomerData()
  } catch (error) {
    let errorMessage = 'An error occurred';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
  }
};

onMounted(async () => {
  await fetchCustomerData();
  await fetchCustomerCredits();
});


</script>


<style scoped>
.suspended-status {
  color: #d9534f;
  /* Red color for suspended status */
  font-weight: bold;
  /* Bold text for emphasis */
  /* Add any other styles you want to enhance the appearance of the suspended status */
}

.bordered-red {
  background-color: #d9534f;
  /* Red background */
  color: #fff;
  /* White text color */
}

.bordered-red:hover {
  background-color: #c9302c;
  /* Darker red background on hover */
}

.bordered-green {
  background-color: #5cb85c;
  /* Green background */
  color: #fff;
  /* White text color */
}

.bordered-green:hover {
  background-color: #4cae4c;
  /* Darker green background on hover */
}
</style>
