<template>
    <Loader :active="loading" message="Please wait..." />

    <div class="domain-projects-page-cont">
        <div class="domain-projects-table-cont">
            <div class="domain-projects-table-box">
                <!-- <div class="table-top-bar">
                    <div class="top-left search-container">
                        <input v-model="searchTerm" @input="fetchProjects" class="form-control search-input"
                            placeholder="Search here..." />
                        <span class="search-icon">🔍</span>
                    </div>
                    <div class="top-right">
                        <a class="export-btn" href="#"><img class="img-fluid" src="images/export.svg" alt="Export">
                            Export</a>
                    </div>
                </div> -->
                <div class="box-top box-top-with-filter-new">
                    <div class="table-top-bar">
                        <div class="top-left">
                            <div class="form-group search-group">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchTerm" @input="fetchProjects" />
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn search-btn">Search</button>
                            </div>
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn export-btn"><img class="flag-img img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-excel-export.svg" alt="Icon Excel Export"> Export</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="data-table-cont">
                        <div class="table-responsive table-normal-view table-domain-projects">
                            <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                                <thead>
                                <!-- <thead class="sticky-top"> -->
                                    <tr>                                        
                                        <!-- <th class="no-wrap align-left"><input class="table-checkbox form-control" type="checkbox"> Domain</th>
                                        <th class="no-wrap align-center">Location</th>
                                        <th class="no-wrap align-center">Limit</th>
                                        <th class="no-wrap align-center">Date Created</th>
                                        <th class="no-wrap align-center">Open Project</th>
                                        <th class="no-wrap align-center">Remove</th> -->
                                        <th class="no-wrap align-left">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Domain</span>
                                                    <img class="img-fluid th-icon" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-question-circle.svg" alt="Question Icon" title="Domain">
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-left">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Location</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-left">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Limit</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-left">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Date Created</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-center">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Open Project</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-center" style="width: 67px;">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Remove</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project in projects" :key="project.id" class="no-wrap">
                                        <td class="align-left">
                                            <!-- <input class="table-checkbox form-control" type="checkbox"> -->
                                            <router-link :to="'/domain-view/' + project.id">{{ project.target }}</router-link>
                                        </td>
                                        <td class="align-left">{{ project.location_name }}</td>
                                        <td class="align-left" >{{ project.limit }}</td>
                                        <td class="align-left">{{ project.created_at }}</td>
                                        <td class="align-center">
                                            <p class="text-highlight">
                                                <router-link :to="'/domain-view/' + project.id" class="text-primary">
                                                    Open <img class="img-fluid"
                                                        src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-open-link.svg"
                                                        alt="Open Link Icon" title="Open">
                                                </router-link>
                                            </p>
                                        </td>
                                        <td class="align-center">
                                            <!-- <div class="action-icon-cont"> -->
                                                <!-- <router-link :to="'/domain-view/' + project.id" class="action-icon icon-view"
                                                    title="View">
                                                    <img class="icon-img" src="images/icon-view.svg" alt="View">
                                                </router-link> -->
                                                <!-- <a class="action-icon icon-edit-project" @click="editProject(project)"
                                                    data-bs-toggle="modal" data-bs-target="#modal-edit-domain-project"
                                                    title="Edit">
                                                    <img class="icon-img" src="images/icon-edit-project.svg" alt="Edit">
                                                </a> -->
                                                <!-- <a class="action-icon icon-delete" @click="deleteProject(project)"
                                                    title="Delete">
                                                    <img class="icon-img" src="images/icon-delete.svg" alt="Delete">
                                                </a> -->
                                            <!-- </div> -->
                                            <a class="table-action-btn-new" href="#" @click="deleteProject(project)"><img class="img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-bin.svg" alt="Remove Icon"></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-container">
                            <!-- <div class="pagination-status">Showing {{ startEntry }} to {{ endEntry }} of {{ totalEntries }} entries</div> -->
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <!-- <li class="page-item previous" :class="{ disabled: currentPage === 1 }"> -->
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" @click="changePage(currentPage - 1)">Previous</a>
                                    </li>
                                    <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                                        <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                    </li>
                                    <!-- <li class="page-item next@@@@" :class="{ disabled: currentPage === totalPages }"> -->
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" @click="changePage(currentPage + 1)">Next</a>
                                    </li>
                                    <!-- <li class="page-item active view-all">
                                        <a class="page-link">View All</a>
                                    </li> -->
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD PROJECT - START -->
    <div id="modal-edit-domain-project" class="common-modal-cont modal fade">
        <div class="modal-dialog modal-972 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Domain Information</span>
                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="common-nav-tab-cont">
                            <ul id="myTab" class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="domain-information-tab" data-bs-toggle="tab"
                                        data-bs-target="#domain-information" type="button" role="tab"
                                        aria-controls="domain-information" aria-selected="true">Domain
                                        Information</button>
                                </li>
                            </ul>
                            <div id="myTabContent" class="tab-content">
                                <div id="domain-information" class="tab-pane fade show active" role="tabpanel"
                                    aria-labelledby="domain-information-tab">
                                    <div class="modal-form-cont">
                                        <div class="row">
                                            <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Domain Name</label>
                                                    <input type="text" v-model="form.target" class="form-control" disabled
                                                        placeholder="Robusmarketing">
                                                </div>
                                            </div>
                                            <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Keyword Limit</label>
                                                    <input type="text" v-model="form.limit" class="form-control" disabled
                                                        placeholder="28">
                                                </div>
                                            </div>
                                            <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <select class="form-control" v-model="form.country"
                                                        @change="onCountryChange">
                                                        <option>Select a Country</option>
                                                        <option v-for="country in regionList"
                                                            :key="country.country_iso_code" :value="country">
                                                            {{ country.location_name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Location</label>
                                                    <div class="autocomplete">
                                                        <input v-model="searchQuery" @input="onChange" type="text"
                                                            placeholder="Enter State (e.g., Florida) and City (e.g., Miami)..."
                                                            class="form-control" />

                                                        <ul v-show="showResults" class="autocomplete-results">
                                                            <li v-for="(result, index) in searchResults" :key="index"
                                                                class="autocomplete-result"
                                                                @click="selectResult(result)">
                                                                {{ result.location_name }}
                                                            </li>
                                                            <li v-if="searchResults?.length === 0 && searchQuery.trim() !== ''"
                                                                class="autocomplete-no-results">
                                                                No results found
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="admin-common-btn btn-bordered btn btn-secondary btn-previous" type="button"
                            data-bs-dismiss="modal">Cancel</button>
                        <button class="admin-common-btn btn btn-primary btn-next" type="button" data-bs-dismiss="modal"  @click="updateDomainProject">Update</button>
                    </div>


                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD PROJECT - END -->

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>


<script setup>
import { onMounted, ref, watch, computed, reactive } from 'vue';
import axiosIns from '@/axios';
import Loader from '../Loader.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CountryService from '@/service/CountryService'

const countryService = new CountryService();
const projects = ref([]);
const currentPage = ref(1);
const perPage = ref(10);
const totalEntries = ref(0);
const searchTerm = ref('');
const loading = ref(false);
const startEntry = computed(() => (currentPage.value - 1) * perPage.value + 1);
const endEntry = computed(() => Math.min(currentPage.value * perPage.value, totalEntries.value));
const totalPages = computed(() => Math.ceil(totalEntries.value / perPage.value));
const regionList = ref(null);
const cityStateData = ref([]);
const pageSize = 20;
const showResults = ref(false);
const searchQuery = ref("")

const form = reactive({
    target: '',
    limit: 50,
    country: null,
    state_city: ''
});

const onChange = () => {
    showResults.value = true;
};

const selectResult = (result) => {
    searchQuery.value = result.location_name;
    showResults.value = false;
    form.state_city = result;
};

const onCountryChange = async () => {
    currentPage.value = 1;
    await fetchCityStateData();
};

const searchResults = computed(() => {
    if (!searchQuery.value.trim()) {
        return [];
    } else {
        return filteredLocations.value.filter(location =>
            location.location_name.toLowerCase().includes(searchQuery.value.trim().toLowerCase())
        );
    }
});

const filteredLocations = computed(() => {
    if (!searchQuery.value) {
        return visibleLocations.value;
    } else {
        const query = searchQuery.value.toLowerCase();
        return cityStateData.value.filter(location => {
            return location.location_name.toLowerCase().includes(query);
        });
    }
});

const fetchCityStateData = async () => {
    try {
        const response = await countryService.getCityAndState(form.country.country_iso_code, currentPage.value, pageSize);
        if (currentPage.value === 1) {
            cityStateData.value = response;
        } else {
            cityStateData.value = [...cityStateData.value, ...response];
        }
    } catch (error) {
        console.error('Error fetching city data:', error);
    }
};

const fetchProjects = async () => {
    loading.value = true;
    try {
        const response = await axiosIns.get('/domain/list-domain-projects', {
            params: {
                page: currentPage.value,
                per_page: perPage.value,
                search: searchTerm.value
            }
        });
        loading.value = false;
        projects.value = response.data.data.domainCheckers;
        totalEntries.value = response.data.data.pagination.total;
    } catch (error) {
        loading.value = false;
        console.error("Error fetching projects:", error);
    }
};

const deleteProject = (project) => {
    const confirmation = confirm(`Are you sure you want to delete project ${project.target}?`);
    if (confirmation) {
        axiosIns.delete(`/domain/delete-domain-project/${project.id}`)
            .then(response => {
                toast.success('Project deleted successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                projects.value = projects.value.filter(p => p.id !== project.id);
            })
            .catch(error => {
                toast.error(`Failed to delete project: ${error.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });

            });
    }
};

const updateDomainProject = () => {
    const projectData = {
        target: form.target,
        limit: form.limit,
        country: form.country ? Number(form.country.location_code) : null,
        state_city: form.state_city ? form.state_city.location_code : null,
        location_name: form.state_city ? form.state_city.location_name : null,
        option_location: form.country ? form.country.location_name : null,
        isProjectUpdate: true
    };

    axiosIns.post(`/domain/update-domain-project/${form.id}`, projectData)
        .then(response => {
            toast.success('Project updated successfully', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
            form.limit = null
            form.target = null
            form.country = null
            form.state_city = null
            fetchProjects();
        })
        .catch(error => {
            toast.error(`Failed to update project: ${error.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        });
};


const changePage = (page) => {
    if (page < 1 || page > totalPages.value) return;
    currentPage.value = page;
    fetchProjects();
};

watch([currentPage, searchTerm], fetchProjects);

onMounted(() => {
    fetchProjects();
    countryService.getCountries().then((data) => {
        regionList.value = data.sort((a, b) => a.location_name.localeCompare(b.location_name));
    });
});
</script>

<style scoped>
.search-container {
    position: relative;
    display: inline-block;
}

.search-input {
    padding-left: 2rem;
    /* Adjust this value as needed */
}

.search-icon {
    position: absolute;
    left: 0.5rem;
    /* Adjust this value as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    /* Prevent the icon from blocking input clicks */
}


.autocomplete {
    position: relative;
}

.autocomplete-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 4px;
    background-color: #fff;
}

.autocomplete-result {
    cursor: pointer;
}

.autocomplete-result:hover {
    background-color: #f0f0f0;
}

.autocomplete-no-results {
    padding: 8px;
    color: #999;
}

input:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}
</style>
