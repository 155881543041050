<template>
  <div v-if="isLoading" class="loading-container" style="text-align: center; padding: 20px;">
    <p style="font-size: 1.5em;">⏳ Please wait while we are fetching your data...</p>
  </div>
  <div v-else>
    <div class="backlink-details-page-cont">

      <div class="backlink-details-page-top">
        <div class="top-title-buttons-cont">
          <h2 class="top-title">
            <img class="img-fluid icon-link"
              src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-link.svg" alt="Icon Link">
            <span><strong>Backlink:</strong> {{ backlinks.target ? 'www.' + backlinks.target : 'N/A' }}</span>
            <img class="img-fluid icon-info"
              src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-info.svg" alt="Icon Info">
          </h2>
          <div class="right-btn-cont">
            <a class="right-btn btn-save" @click="saveProject()"><img class="img-fluid icon-info"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-save.svg"
                alt="Icon Info">{{ isProjectSaved ? 'Saved' : 'Save'
              }}</a>
          </div>
        </div>

        <div class="top-total-info-box-cont">
          <div class="row top-total-info-box-row">
            <div class="col-12 col-md-12 col-lg-8 top-total-info-box-col col-left-chart">
              <div class="total-info-box total-info-chart">
                <div class="row total-info-chart-row">
                  <div class="col-12 col-md-5 col-lg-4 total-info-chart-col col-cart">
                    <div class="chart-cont">
                      <apexchart type="donut" width="312" :options="chartOptionsYears" :series="seriesYears">
                      </apexchart>
                    </div>
                  </div>
                  <div class="col-12 col-md-7 col-lg-8 total-info-chart-col col-chart-data">
                    <div class="total-info-chart-data">
                      <div class="row total-info-chart-data-row">
                        <div class="col-12 col-md-6 col-lg-6 total-info-chart-data-col">
                          <div class="chart-data-box">
                            <div class="chart-color-box color-one"></div>
                            <div class="data-div">
                              <h2 class="data-total">{{ formatSummaryValue(summary.backlinks) }}</h2>
                              <h3 class="data-title">Total Backlinks</h3>
                              <p class="data-desc">The total number of live backlinks pointing to the domain.</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 total-info-chart-data-col">
                          <div class="chart-data-box">
                            <div class="chart-color-box color-two"></div>
                            <div class="data-div">
                              <h2 class="data-total">{{ formatSummaryValue(summary.broken_backlinks) }}</h2>
                              <h3 class="data-title">Broken Backlinks</h3>
                              <p class="data-desc">The count of backlinks that are no longer functional</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 total-info-chart-data-col">
                          <div class="chart-data-box">
                            <div class="chart-color-box color-three"></div>
                            <div class="data-div">
                              <h2 class="data-total">{{ formatSummaryValue(summary.external_links_count) }}</h2>
                              <h3 class="data-title">External Links Count</h3>
                              <p class="data-desc">The total number of external links found on the domain</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 total-info-chart-data-col">
                          <div class="chart-data-box">
                            <div class="chart-color-box color-four"></div>
                            <div class="data-div">
                              <h2 class="data-total">{{ formatSummaryValue(summary.internal_links_count) }}</h2>
                              <h3 class="data-title">Internal Links Count</h3>
                              <p class="data-desc">The total number of internal links within the domain</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2 top-total-info-box-col col-referring-domains">
              <div class="total-info-box referring-domains">
                <img class="box-icon img-fluid"
                  src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-globe-www.svg"
                  alt="Icon Globe WWW">
                <h2 class="total">{{ formatSummaryValue(formatNumber(summary.referring_domains)) }}</h2>
                <h4 class="title">Referring Domains</h4>
                <p class="desc">The number of unique domains that have links to the target</p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2 top-total-info-box-col col-crawled-pages">
              <div class="total-info-box crawled-pages">
                <img class="box-icon img-fluid"
                  src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-crawled.svg"
                  alt="Icon Crawled">
                <h2 class="total">{{ formatSummaryValue(formatNumber(summary.crawled_pages)) }}</h2>
                <h4 class="title">Crawled Pages</h4>
                <p class="desc">The number of pages on the domain that have been crawled</p>
              </div>
            </div>
          </div>
        </div>

        <div class="detailed-overview-cont">
          <div class="top-cont">
            <h2 class="title">Detailed Overview:</h2>
            <div class="top-info-cont">
              <div class="info-box">
                <img class="img-fluid"
                  src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-rank.svg"
                  alt="Icon Rank">
                <span>Rank: {{ summary.rank }}</span>
              </div>
              <div class="info-box">
                <img class="img-fluid"
                  src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-calender.svg"
                  alt="Icon Calender">
                <span>First Seen: {{ summary.first_seen }}</span>
              </div>
            </div>
          </div>
          <div class="row detailed-overview-box-row">

            <div class="col-12 col-md-4 col-lg-4 detailed-overview-box-col col-referring-domains">
              <div class="detailed-overview-box referring-domains-box">
                <h3 class="box-title">
                  <span class="text">Referring Domains</span>
                  <a class="box-option-dot" href="#">
                    <img class="img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-three-dot.svg"
                      alt="Icon Three Dot">
                  </a>
                </h3>
                <div class="referring-domains-data">
                  <div class="data-row-box bg-green">
                    <span class="data-number">{{ summary.referring_domains }}</span>
                    <span class="data-title">Total Referring Domains</span>
                  </div>
                  <div class="data-row-box bg-red">
                    <span class="data-number">{{ summary.referring_pages_nofollow }}</span>
                    <span class="data-title">Nofollow Referring Domains</span>
                  </div>
                  <div class="data-row-box bg-green">
                    <span class="data-number">{{ summary.referring_main_domains }}</span>
                    <span class="data-title">Main Referring Domains</span>
                  </div>
                  <div class="data-row-box bg-red">
                    <span class="data-number">{{ summary.referring_main_domains_nofollow }}</span>
                    <span class="data-title">Nofollow Main Domains</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-8 col-lg-8 detailed-overview-box-col col-referring-links-by-type">
              <div class="detailed-overview-box referring-links-by-type-box">
                <h3 class="box-title">
                  <span class="text">Referring Links by Type</span>
                  <a class="box-option-dot" href="#">
                    <img class="img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-three-dot.svg"
                      alt="Icon Three Dot">
                  </a>
                </h3>
                <div class="chart-wrapper">
                  <div class="chart-container">
                    <apexchart type="donut" width="374" :options="chartOptionsDonut" :series="seriesDonut" />
                  </div>
                  <div class="data-container">
                    <div v-for="(label, index) in chartLabelsDonut" :key="index" class="data-item">
                      <span class="data-label">{{ label }}</span>
                      <span class="data-value">{{
                        seriesDonut[index].toLocaleString()
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 col-lg-4 detailed-overview-box-col col-referring-links-by-tld">
              <div class="detailed-overview-box referring-links-by-tld-box">
                <h3 class="box-title title-blue">
                  <span class="text">Referring Links by TLD</span>
                  <a class="box-option-dot" href="#">
                    <img class="img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-three-dot.svg"
                      alt="Icon Three Dot">
                  </a>
                </h3>
                <div class="referring-bottom-boxes-data">
                  <div class="data-row-box" v-for="(count, domain) in summary.referring_links_tld" :key="domain">
                    <span class="data-title">{{ domain }}</span>
                    <span class="data-number">{{ count }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 col-lg-4 detailed-overview-box-col col-backlinks-overview">
              <div class="detailed-overview-box backlinks-overview-box">
                <h3 class="box-title title-blue">
                  <span class="text">Backlinks Info</span>
                  <a class="box-option-dot" href="#">
                    <img class="img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-three-dot.svg"
                      alt="Icon Three Dot">
                  </a>
                </h3>
                <div class="referring-bottom-boxes-data">
                  <div class="data-row-box">
                    <span class="data-title">Spam Score</span>
                    <span class="data-number">{{ summary?.backlinks_spam_score || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">Referring IPs</span>
                    <span class="data-number">{{ summary.info?.ip_address || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">Referring Subnets</span>
                    <span class="data-number">{{ summary.info?.platform_type.join(', ') || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">CMS</span>
                    <span class="data-number">{{ summary.info?.cms || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">Country</span>
                    <span class="data-number">{{ summary.info?.country || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">Server</span>
                    <span class="data-number">{{ summary.info?.server || '-' }}</span>
                  </div>
                  <div class="data-row-box">
                    <span class="data-title">Target Spam Score</span>
                    <span class="data-number">{{ summary.info?.target_spam_score || '-' }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 col-lg-4 detailed-overview-box-col col-referring-links-by-country">
              <div class="detailed-overview-box referring-links-by-country-box">
                <h3 class="box-title title-blue">
                  <span class="text">Referring Links by Country</span>
                  <a class="box-option-dot" href="#">
                    <img class="img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-three-dot.svg"
                      alt="Icon Three Dot">
                  </a>
                </h3>
                <div class="referring-bottom-boxes-data">
                  <div class="data-row-box top-title-row">
                    <span class="data-title">Country Code</span>
                    <span class="data-number">Count</span>
                  </div>
                  <div class="data-row-box" v-for="(count, country) in summary.referring_links_countries"
                    :key="country">
                    <span class="data-title">
                      <template v-if="country === 'WW'">
                        <span>🌍 (Worldwide)</span>
                      </template>
                      <template v-else>
                        <img v-if="countryFlagMap[country]" :src="countryFlagMap[country]" class="flag-img img-fluid"
                          :alt="countryNames[country] || 'Flag'">
                        <span v-else>🏳️</span>
                        {{ country || '-' }} ({{ countryNames[country] || 'Unknown' }})
                      </template>
                    </span>
                    <span class="data-number">{{ count }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 detailed-overview-box-col col-new-lost-backlinks">
              <div class="detailed-overview-box new-lost-backlinks-box">
               
                <div class="new-lost-backlinks-chart">
                  <apexchart type="bar" height="358px" :options="negativeColumnOptions"
                    :series="negativeColumnSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="backlink-table-cont">
        <div class="backlink-table-box">
          <div class="box-top box-top-with-filter-new justify-content-between">
            <h4 class="box-title">Backlinks Analysis <img class="img-fluid th-icon"
                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-question-circle-small.svg"
                alt="Question Icon" title="Backlinks Analysis" style="cursor: pointer;"></h4>
            <div class="table-top-bar">
              <div class="top-left">
                <div class="form-group search-group">
                  <input v-model="searchQuery" class="table-search-input" @input="searchFunction"
                    placeholder="Search Backlinks" />
                </div>
              </div>
              <div class="top-right">
                <div class="form-group btn-group">
                  <button class="btn btn-light filter-btn" @click="setFilter('all')"
                    :class="{ active: selectedFilter === 'all' }">All links</button>
                </div>
                <div class="form-group btn-group">
                  <button class="btn btn-light filter-btn" @click="setFilter('lost')"
                    :class="{ active: selectedFilter === 'lost' }">Lost</button>
                </div>
                <div class="form-group btn-group">
                  <button class="btn btn-light filter-btn" @click="setFilter('new')"
                    :class="{ active: selectedFilter === 'new' }">New</button>
                </div>
                <div class="form-group btn-group">
                  <button class="btn btn-light filter-btn export-btn">
                    <img class="flag-img img-fluid"
                      src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-excel-export.svg"
                      alt="Icon Excel Export">Export
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="box-body">
            <div class="data-table-cont">
              <div class="table-responsive table-normal-view table-backlink table-backlinks-analysis"
                style="max-height: 2000px; overflow-y: auto;" @scroll="handleScroll">
                <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                  <thead>
                    <tr>
                      <th class="no-wrap" style="width: 310px !important;">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Page Title</span>
                            <!-- <img class="img-fluid th-icon" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-question-circle.svg" alt="Question Icon" title="Page Title" style="cursor: pointer;"> -->
                          </div>
                        </div>
                      </th>
                      <th class="no-wrap" @click="sortBy('rank')" :class="{ 'sorted': sortByColumn === 'rank' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt" :class="{ 'bold-text': sortByColumn === 'rank' }">Domain<br>Rank</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'rank' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'rank' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('page_from_rank')"
                        :class="{ 'sorted': sortByColumn === 'page_from_rank' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt"
                              :class="{ 'bold-text': sortByColumn === 'page_from_rank' }">Page<br>Rank</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'page_from_rank' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'page_from_rank' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('page_from_external_links')"
                        :class="{ 'sorted': sortByColumn === 'page_from_external_links' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt"
                              :class="{ 'bold-text': sortByColumn === 'page_from_external_links' }">External<br>Links</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'page_from_external_links' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'page_from_external_links' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('page_from_internal_links')"
                        :class="{ 'sorted': sortByColumn === 'page_from_internal_links' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt"
                              :class="{ 'bold-text': sortByColumn === 'page_from_internal_links' }">Internal<br>Links</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'page_from_internal_links' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'page_from_internal_links' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('anchor')" :class="{ 'sorted': sortByColumn === 'anchor' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt"
                              :class="{ 'bold-text': sortByColumn === 'anchor' }">Anchor<br>Text</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'anchor' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'anchor' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('first_seen')"
                        :class="{ 'sorted': sortByColumn === 'first_seen' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt" :class="{ 'bold-text': sortByColumn === 'first_seen' }">
                              First Seen</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'first_seen' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'first_seen' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('last_seen')"
                        :class="{ 'sorted': sortByColumn === 'last_seen' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt" :class="{ 'bold-text': sortByColumn === 'last_seen' }">
                              Last Seen</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'last_seen' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'last_seen' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('is_lost')" :class="{ 'sorted': sortByColumn === 'is_lost' }">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt" :class="{ 'bold-text': sortByColumn === 'is_lost' }">
                              Link<br>Active
                            </span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'is_lost' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'is_lost' }"
                                src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span>
                          </div>
                        </div>
                      </th>


                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="visibleData.length > 0">
                      <tr v-for="(item, index) in visibleData" :key="index">
                        <td class="align-left">
                          <p class="text-highlight">{{ item.page_from_title }}</p>
                          <a :href="item.url_from" target="_blank" rel="noopener noreferrer" class="domain-link">
                            <strong>Target: </strong>{{ item.url_from }}
                          </a>
                        </td>
                        <td class="align-left">{{ item.rank }}</td>
                        <td class="align-left">{{ item.page_from_rank }}</td>
                        <td class="align-left">{{ item.page_from_external_links }}</td>
                        <td class="align-left">{{ item.page_from_internal_links }}</td>
                        <td class="align-left">{{ item.anchor }}</td>
                        <td class="align-left">{{ formatDate(item.first_seen) }}</td>
                        <td class="align-left">{{ formatDate(item.last_seen) }}</td>
                        <td class="align-left">
                          <p :class="{ 'lost-yes-p': true, 'lost': item.is_lost, 'yes': !item.is_lost }">
                            {{ item.is_lost ? 'Lost' : 'Yes' }}
                            <img class="icon-link"
                              :src="item.is_lost ? 'http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-link-lost-new.svg' : 'http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-link-new.svg'"
                              alt="Icon Link">
                          </p>
                        </td>
                        <!-- <td class="align-left">{{ item.is_lost ? 'No' : 'Yes' }}</td> -->
                      </tr>
                      <tr v-for="n in skeletonRows" :key="'skeleton-' + n" v-if="loading">
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                        <td class="skeleton-cell">
                          <div class="skeleton-loader"></div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="9" class="align-center">
                          <p style="font-size: 1.2em;">🧐 No Data Available</p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <!-- <div class="pagination-container">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                 

                    <li v-for="page in paginatedBacklinks.totalPages" :key="page" class="page-item"
                      @click="setPage(page)" :class="{ active: currentPage === page }">
                      <a class="page-link" href="javascript:void(0)">{{ page }}</a>
                    </li>

                  

                    <li class="page-item active view-all">
                        <a class="page-link">View All</a>
                    </li>

                  </ul>
                </nav>
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-cont">
      <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
        Number
        438499640</p>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, computed, onUnmounted, watch, onBeforeMount } from 'vue';
import axiosIns from '@/axios';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const router = useRouter()
const allChecked = ref(true);
const newChecked = ref(true);
const lostChecked = ref(true);
const loading = ref(false);

const countryFlagMap = {
  "BR": "https://flagcdn.com/br.svg",   // Brazil
  "CC": "https://flagcdn.com/cc.svg",   // Cocos (Keeling) Islands
  "CN": "https://flagcdn.com/cn.svg",   // China
  "IO": "https://flagcdn.com/io.svg",   // British Indian Ocean Territory
  "KR": "https://flagcdn.com/kr.svg",   // South Korea
  "UA": "https://flagcdn.com/ua.svg",   // Ukraine
  "US": "https://flagcdn.com/us.svg",   // United States
  "WW": "https://flagcdn.com/ww.svg",   // Worldwide (custom icon)
  "YT": "https://flagcdn.com/yt.svg",   // Mayotte
  "FR": "https://flagcdn.com/fr.svg",   // France
  "AU": "https://flagcdn.com/au.svg",   // Australia
  "IE": "https://flagcdn.com/ie.svg",   // Ireland
  "IR": "https://flagcdn.com/ir.svg",   // Iran
  "IN": "https://flagcdn.com/in.svg",   // India
  "PK": "https://flagcdn.com/pk.svg",   // Pakistan
  "RU": "https://flagcdn.com/ru.svg",   // Russia
  "SG": "https://flagcdn.com/sg.svg",   // Singapore
  "TH": "https://flagcdn.com/th.svg",   // Thailand
  "TR": "https://flagcdn.com/tr.svg",   // Turkey
  "VN": "https://flagcdn.com/vn.svg",   // Vietnam
  "SK": "https://flagcdn.com/sk.svg",   // Slovakia
  "GB": "https://flagcdn.com/gb.svg",   // United Kingdom
  "CO": "https://flagcdn.com/co.svg",   // Colombia
  "ME": "https://flagcdn.com/me.svg",   // Montenegro
  "PH": "https://flagcdn.com/ph.svg",   // Philippines
  "DE": "https://flagcdn.com/de.svg",   // Germany
  "ES": "https://flagcdn.com/es.svg",   // Spain
  "IT": "https://flagcdn.com/it.svg",   // Italy
  "JP": "https://flagcdn.com/jp.svg",   // Japan
  "MX": "https://flagcdn.com/mx.svg",   // Mexico
  "NL": "https://flagcdn.com/nl.svg",   // Netherlands
  "SE": "https://flagcdn.com/se.svg",   // Sweden
  "CH": "https://flagcdn.com/ch.svg",   // Switzerland
  "BE": "https://flagcdn.com/be.svg",   // Belgium
  "AR": "https://flagcdn.com/ar.svg",   // Argentina
  "CA": "https://flagcdn.com/ca.svg",   // Canada
  "SA": "https://flagcdn.com/sa.svg",   // Saudi Arabia
  "MY": "https://flagcdn.com/my.svg",   // Malaysia
  "NZ": "https://flagcdn.com/nz.svg",   // New Zealand
  "FI": "https://flagcdn.com/fi.svg",   // Finland
  "ZA": "https://flagcdn.com/za.svg",   // South Africa
  "ID": "https://flagcdn.com/id.svg",   // Indonesia
  "BD": "https://flagcdn.com/bd.svg",   // Bangladesh
  "EG": "https://flagcdn.com/eg.svg",   // Egypt
  "NO": "https://flagcdn.com/no.svg",   // Norway
  "PL": "https://flagcdn.com/pl.svg",   // Poland
  "GR": "https://flagcdn.com/gr.svg",   // Greece
  "KE": "https://flagcdn.com/ke.svg",   // Kenya
  "PT": "https://flagcdn.com/pt.svg",   // Portugal
  "AE": "https://flagcdn.com/ae.svg",   // United Arab Emirates
  "AF": "https://flagcdn.com/af.svg",   // Afghanistan
  "AG": "https://flagcdn.com/ag.svg",   // Antigua and Barbuda
  "AI": "https://flagcdn.com/ai.svg",   // Anguilla
  "AL": "https://flagcdn.com/al.svg",   // Albania
  "AM": "https://flagcdn.com/am.svg",   // Armenia
  "AS": "https://flagcdn.com/as.svg",   // American Samoa
  "AT": "https://flagcdn.com/at.svg",   // Austria
  "AZ": "https://flagcdn.com/az.svg",   // Azerbaijan
  "BA": "https://flagcdn.com/ba.svg",   // Bosnia and Herzegovina
  "BF": "https://flagcdn.com/bf.svg",   // Burkina Faso
  "BG": "https://flagcdn.com/bg.svg",   // Bulgaria
  "BH": "https://flagcdn.com/bh.svg",   // Bahrain
  "BN": "https://flagcdn.com/bn.svg",   // Brunei
  "BO": "https://flagcdn.com/bo.svg",   // Bolivia
  "BS": "https://flagcdn.com/bs.svg",   // Bahamas
  "BT": "https://flagcdn.com/bt.svg",   // Bhutan
  "BY": "https://flagcdn.com/by.svg",   // Belarus
  "BZ": "https://flagcdn.com/bz.svg",   // Belize
  "CF": "https://flagcdn.com/cf.svg",   // Central African Republic
  "CI": "https://flagcdn.com/ci.svg",   // Ivory Coast
  "CL": "https://flagcdn.com/cl.svg",   // Chile
  "CM": "https://flagcdn.com/cm.svg",   // Cameroon
  "CR": "https://flagcdn.com/cr.svg",   // Costa Rica
  "CV": "https://flagcdn.com/cv.svg",   // Cape Verde
  "CX": "https://flagcdn.com/cx.svg",   // Christmas Island
  "DK": "https://flagcdn.com/dk.svg",   // Denmark
  "DO": "https://flagcdn.com/do.svg",   // Dominican Republic
  "DZ": "https://flagcdn.com/dz.svg",   // Algeria
  "EE": "https://flagcdn.com/ee.svg",   // Estonia
  "ET": "https://flagcdn.com/et.svg",   // Ethiopia
  "FM": "https://flagcdn.com/fm.svg",   // Micronesia
  "GA": "https://flagcdn.com/ga.svg",   // Gabon
  "GD": "https://flagcdn.com/gd.svg",   // Grenada
  "GE": "https://flagcdn.com/ge.svg",   // Georgia
  "GG": "https://flagcdn.com/gg.svg",   // Guernsey
  "GH": "https://flagcdn.com/gh.svg",   // Ghana
  "GL": "https://flagcdn.com/gl.svg",   // Greenland
  "GT": "https://flagcdn.com/gt.svg",   // Guatemala
  "GU": "https://flagcdn.com/gu.svg",   // Guam
  "GY": "https://flagcdn.com/gy.svg",   // Guyana
  "HK": "https://flagcdn.com/hk.svg",   // Hong Kong
  "HN": "https://flagcdn.com/hn.svg",   // Honduras
  "HR": "https://flagcdn.com/hr.svg",   // Croatia
  "HU": "https://flagcdn.com/hu.svg",   // Hungary
  "IL": "https://flagcdn.com/il.svg",   // Israel
  "IQ": "https://flagcdn.com/iq.svg",   // Iraq
  "IS": "https://flagcdn.com/is.svg",   // Iceland
  "JO": "https://flagcdn.com/jo.svg",   // Jordan
  "KG": "https://flagcdn.com/kg.svg",   // Kyrgyzstan
  "KH": "https://flagcdn.com/kh.svg",   // Cambodia
  "KM": "https://flagcdn.com/km.svg",   // Comoros
  "KN": "https://flagcdn.com/kn.svg",   // Saint Kitts and Nevis
  "KY": "https://flagcdn.com/ky.svg",   // Cayman Islands
  "KZ": "https://flagcdn.com/kz.svg",   // Kazakhstan
  "LA": "https://flagcdn.com/la.svg",   // Laos
  "LB": "https://flagcdn.com/lb.svg",   // Lebanon
  "LI": "https://flagcdn.com/li.svg",   // Liechtenstein
  "LK": "https://flagcdn.com/lk.svg",   // Sri Lanka
  "LS": "https://flagcdn.com/ls.svg",   // Lesotho
  "LT": "https://flagcdn.com/lt.svg",   // Lithuania
  "LU": "https://flagcdn.com/lu.svg",   // Luxembourg
  "LV": "https://flagcdn.com/lv.svg",   // Latvia
  "LY": "https://flagcdn.com/ly.svg",   // Libya
  "MC": "https://flagcdn.com/mc.svg",   // Monaco
  "MD": "https://flagcdn.com/md.svg",   // Moldova
  "MG": "https://flagcdn.com/mg.svg",   // Madagascar
  "MK": "https://flagcdn.com/mk.svg",   // North Macedonia
  "ML": "https://flagcdn.com/ml.svg",   // Mali
  "MM": "https://flagcdn.com/mm.svg",   // Myanmar
  "MO": "https://flagcdn.com/mo.svg",   // Macau
  "MT": "https://flagcdn.com/mt.svg",   // Malta
  "MU": "https://flagcdn.com/mu.svg",   // Mauritius
  "MV": "https://flagcdn.com/mv.svg",   // Maldives
  "MW": "https://flagcdn.com/mw.svg",   // Malawi
  "MZ": "https://flagcdn.com/mz.svg",   // Mozambique
  "NA": "https://flagcdn.com/na.svg",   // Namibia
  "NE": "https://flagcdn.com/ne.svg",   // Niger
  "NI": "https://flagcdn.com/ni.svg",   // Nicaragua
  "NP": "https://flagcdn.com/np.svg",   // Nepal
  "OM": "https://flagcdn.com/om.svg",   // Oman
  "PA": "https://flagcdn.com/pa.svg",   // Panama
  "PE": "https://flagcdn.com/pe.svg",   // Peru
  "PF": "https://flagcdn.com/pf.svg",   // French Polynesia
  "PS": "https://flagcdn.com/ps.svg",   // Palestine
  "PW": "https://flagcdn.com/pw.svg",   // Palau
  "PY": "https://flagcdn.com/py.svg",   // Paraguay
  "QA": "https://flagcdn.com/qa.svg",   // Qatar
  "RE": "https://flagcdn.com/re.svg",   // Réunion
  "RO": "https://flagcdn.com/ro.svg",   // Romania
  "RW": "https://flagcdn.com/rw.svg",   // Rwanda
  "SB": "https://flagcdn.com/sb.svg",   // Solomon Islands
  "SC": "https://flagcdn.com/sc.svg",   // Seychelles
  "SI": "https://flagcdn.com/si.svg",   // Slovenia
  "SM": "https://flagcdn.com/sm.svg",   // San Marino
  "SO": "https://flagcdn.com/so.svg",   // Somalia
  "SR": "https://flagcdn.com/sr.svg",   // Suriname
  "ST": "https://flagcdn.com/st.svg",   // São Tomé and Príncipe
  "SV": "https://flagcdn.com/sv.svg",   // El Salvador
  "SZ": "https://flagcdn.com/sz.svg",   // Eswatini
  "TC": "https://flagcdn.com/tc.svg",   // Turks and Caicos Islands
  "TG": "https://flagcdn.com/tg.svg",   // Togo
  "TJ": "https://flagcdn.com/tj.svg",   // Tajikistan
  "TL": "https://flagcdn.com/tl.svg",   // East Timor
  "TM": "https://flagcdn.com/tm.svg",   // Turkmenistan
  "TN": "https://flagcdn.com/tn.svg",   // Tunisia
  "TT": "https://flagcdn.com/tt.svg",   // Trinidad and Tobago
  "TZ": "https://flagcdn.com/tz.svg",   // Tanzania
  "UG": "https://flagcdn.com/ug.svg",   // Uganda
  "UY": "https://flagcdn.com/uy.svg",   // Uruguay
  "UZ": "https://flagcdn.com/uz.svg",   // Uzbekistan
  "VC": "https://flagcdn.com/vc.svg",   // Saint Vincent and the Grenadines
  "VE": "https://flagcdn.com/ve.svg",   // Venezuela
  "VG": "https://flagcdn.com/vg.svg",   // British Virgin Islands
  "VI": "https://flagcdn.com/vi.svg",   // U.S. Virgin Islands
  "XK": "https://flagcdn.com/xk.svg",   // Kosovo
  "ZM": "https://flagcdn.com/zm.svg",   // Zambia
  "ZW": "https://flagcdn.com/zw.svg"    // Zimbabwe
};

const countryNames = {
  "BR": "Brazil",
  "CC": "Cocos (Keeling) Islands",
  "CN": "China",
  "IO": "British Indian Ocean Territory",
  "KR": "South Korea",
  "UA": "Ukraine",
  "US": "United States",
  "WW": "Worldwide (custom icon)",
  "YT": "Mayotte",
  "FR": "France",
  "AU": "Australia",
  "IE": "Ireland",
  "IR": "Iran",
  "IN": "India",
  "PK": "Pakistan",
  "RU": "Russia",
  "SG": "Singapore",
  "TH": "Thailand",
  "TR": "Turkey",
  "VN": "Vietnam",
  "SK": "Slovakia",
  "GB": "United Kingdom",
  "CO": "Colombia",
  "ME": "Montenegro",
  "PH": "Philippines",
  "DE": "Germany",
  "ES": "Spain",
  "IT": "Italy",
  "JP": "Japan",
  "MX": "Mexico",
  "NL": "Netherlands",
  "SE": "Sweden",
  "CH": "Switzerland",
  "BE": "Belgium",
  "AR": "Argentina",
  "CA": "Canada",
  "SA": "Saudi Arabia",
  "MY": "Malaysia",
  "NZ": "New Zealand",
  "FI": "Finland",
  "ZA": "South Africa",
  "ID": "Indonesia",
  "BD": "Bangladesh",
  "EG": "Egypt",
  "NO": "Norway",
  "PL": "Poland",
  "GR": "Greece",
  "KE": "Kenya",
  "PT": "Portugal",
  "AE": "United Arab Emirates",
  "AF": "Afghanistan",
  "AG": "Antigua and Barbuda",
  "AI": "Anguilla",
  "AL": "Albania",
  "AM": "Armenia",
  "AS": "American Samoa",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BA": "Bosnia and Herzegovina",
  "BF": "Burkina Faso",
  "BG": "Bulgaria",
  "BH": "Bahrain",
  "BN": "Brunei",
  "BO": "Bolivia",
  "BS": "Bahamas",
  "BT": "Bhutan",
  "BY": "Belarus",
  "BZ": "Belize",
  "CF": "Central African Republic",
  "CI": "Ivory Coast",
  "CL": "Chile",
  "CM": "Cameroon",
  "CR": "Costa Rica",
  "CV": "Cape Verde",
  "CX": "Christmas Island",
  "DK": "Denmark",
  "DO": "Dominican Republic",
  "DZ": "Algeria",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FM": "Micronesia",
  "GA": "Gabon",
  "GD": "Grenada",
  "GE": "Georgia",
  "GG": "Guernsey",
  "GH": "Ghana",
  "GL": "Greenland",
  "GT": "Guatemala",
  "GU": "Guam",
  "GY": "Guyana",
  "HK": "Hong Kong",
  "HN": "Honduras",
  "HR": "Croatia",
  "HU": "Hungary",
  "IL": "Israel",
  "IQ": "Iraq",
  "IS": "Iceland",
  "JO": "Jordan",
  "KG": "Kyrgyzstan",
  "KH": "Cambodia",
  "KM": "Comoros",
  "KN": "Saint Kitts and Nevis",
  "KY": "Cayman Islands",
  "KZ": "Kazakhstan",
  "LA": "Laos",
  "LB": "Lebanon",
  "LI": "Liechtenstein",
  "LK": "Sri Lanka",
  "LS": "Lesotho",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "LV": "Latvia",
  "LY": "Libya",
  "MC": "Monaco",
  "MD": "Moldova",
  "MG": "Madagascar",
  "MK": "North Macedonia",
  "ML": "Mali",
  "MM": "Myanmar",
  "MO": "Macau",
  "MT": "Malta",
  "MU": "Mauritius",
  "MV": "Maldives",
  "MW": "Malawi",
  "MZ": "Mozambique",
  "NA": "Namibia",
  "NE": "Niger",
  "NI": "Nicaragua",
  "NP": "Nepal",
  "OM": "Oman",
  "PA": "Panama",
  "PE": "Peru",
  "PF": "French Polynesia",
  "PS": "Palestine",
  "PW": "Palau",
  "PY": "Paraguay",
  "QA": "Qatar",
  "RE": "Réunion",
  "RO": "Romania",
  "RW": "Rwanda",
  "SB": "Solomon Islands",
  "SC": "Seychelles",
  "SI": "Slovenia",
  "SM": "San Marino",
  "SO": "Somalia",
  "SR": "Suriname",
  "ST": "São Tomé and Príncipe",
  "SV": "El Salvador",
  "SZ": "Eswatini",
  "TC": "Turks and Caicos Islands",
  "TG": "Togo",
  "TJ": "Tajikistan",
  "TL": "East Timor",
  "TM": "Turkmenistan",
  "TN": "Tunisia",
  "TO": "Tonga",
  "TR": "Turkey",
  "TT": "Trinidad and Tobago",
  "TV": "Tuvalu",
  "TZ": "Tanzania",
  "UA": "Ukraine",
  "UG": "Uganda",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VC": "Saint Vincent and the Grenadines",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VU": "Vanuatu",
  "WS": "Samoa",
  "YE": "Yemen",
  "ZA": "South Africa",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
};

const backlinks = ref({})
const summary = ref({})
const backlinksArray = ref([])
const seriesYears = ref([0, 0, 0, 0]);
const seriesDonut = ref([0, 0, 0, 0]);
const visibleData = ref([]);
const negativeColumnSeries = ref([
  {
    name: 'New',
    data: [],  // Data for new links
  },
  {
    name: 'Lost',
    data: [],  // Data for lost links
  },
]);

const negativeColumnOptions = ref({
  chart: {
    type: 'bar',
    height: 400,
    stacked: true,  // Enable stacked bar chart
    toolbar: { show: true },
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      columnWidth: '20%',
      dataLabels: {
        position: 'top', // Positions data labels on top of each bar segment
      },
      colors: {
        ranges: [
          { from: -10000, to: 0, color: '#F3896F' },  // Color for lost links (if negative)
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    title: {
      text: 'Numbers',
    },
  },
  xaxis: {
    categories: [], // Fill in categories dynamically with dates
  },
  colors: ['#8AC472', '#F3896F'], // Colors for new and lost series
  tooltip: {
    shared: true,  // Show both new and lost values in a single tooltip
    intersect: false,
  },
  legend: {
    position: 'top',
  },
});


const selectedFilter = ref('all');

const currentPage = ref(1);
const itemsPerPage = 40;
const isProjectSaved = ref(false);
const isLoading = ref(false);

const sortByColumn = ref('');
const sortDirection = ref('asc');

const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
};

const sortBy = (column) => {
  if (sortByColumn.value === column) {
    toggleSortDirection();
    sortbacklinks();
  } else {
    sortByColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const paginatedBacklinks = computed(() => {
  let filtered = backlinksArray.value;

  if (selectedFilter.value === 'lost') {
    filtered = filtered.filter(link => link.is_lost);
  } else if (selectedFilter.value === 'new') {
    filtered = filtered.filter(link => link.is_new);
  }

  // if (searchQuery.value) {
  //   const query = searchQuery.value.toLowerCase();
  //   filtered = filtered.filter(link =>
  //     link.anchor.toLowerCase().includes(query) ||
  //     link.domain_from.toLowerCase().includes(query)
  //   );
  // }

  const start = (currentPage.value - 1) * itemsPerPage;
  const paginatedData = filtered.slice(start, start + itemsPerPage);

  return {
    data: paginatedData,
    totalPages: Math.ceil(filtered.length / itemsPerPage),
  };
});

const itemsToShow = 10;
let currentIndex = 0;

const loadMoreData = () => {

  if (currentIndex < paginatedBacklinks.value.data.length) {
    loading.value = true;
    setTimeout(() => {
      const nextItems = paginatedBacklinks.value.data.slice(
        currentIndex,
        currentIndex + itemsToShow
      );
      visibleData.value.push(...nextItems);
      currentIndex += itemsToShow;
      loading.value = false;
    }, 2000);
  }
};

const handleScroll = (event) => {

  const { scrollTop, clientHeight, scrollHeight } = event.target;
  if (scrollTop + clientHeight >= scrollHeight - 5) {
    loadMoreData();
  }
};

const skeletonRows = 10;

const updateChartData = () => {
  negativeColumnSeries.value[0].data = [];
  negativeColumnOptions.value.xaxis.categories = [];

  const projectDataDifferences = [];

  projectDataByWeeks.value.forEach(item => {
    let shouldInclude = false;


    if (allChecked.value) {
      shouldInclude = true;
    } else {
      if (newChecked.value && item.new > 0) {
        shouldInclude = true;
      }
      if (lostChecked.value && item.lost > 0) {
        shouldInclude = true;
      }
    }

    if (shouldInclude) {
      negativeColumnOptions.value.xaxis.categories.push(item.startDate);

      if (newChecked.value && !lostChecked.value) {
        projectDataDifferences.push(item.new || 0);
      }
      else if (lostChecked.value && !newChecked.value) {
        projectDataDifferences.push(-(item.lost || 0));
      }
      else if (newChecked.value && lostChecked.value) {
        const difference = (item.new || 0) - (item.lost || 0);
        projectDataDifferences.push(difference);
      }
    }
  });

  negativeColumnSeries.value[0].data = projectDataDifferences;
};

const sortbacklinks = () => {
  if (sortByColumn.value === '') return;

  visibleData.value.sort((a, b) => {
    let modifier = 1;
    if (sortDirection.value === 'desc') modifier = -1;

    if (a[sortByColumn.value] < b[sortByColumn.value]) return -1 * modifier;
    if (a[sortByColumn.value] > b[sortByColumn.value]) return 1 * modifier;
    return 0;
  });
};

// const setPage = (page) => {
//   currentPage.value = page;
// };

// const nextPage = () => {
//   const totalPages = paginatedBacklinks.value.totalPages;
//   if (currentPage.value < totalPages) {
//     currentPage.value++;
//   }
// };

// const previousPage = () => {
//   if (currentPage.value > 1) {
//     currentPage.value--;
//   }
// };

const setFilter = (filter) => {
  selectedFilter.value = filter;

  let filtered = backlinksArray.value;

  if (selectedFilter.value === 'lost') {
    filtered = filtered.filter(link => link.is_lost);
  } else if (selectedFilter.value === 'new') {
    filtered = filtered.filter(link => link.is_new);
  }

  visibleData.value = filtered;

  currentPage.value = 1;
};
const searchQuery = ref('');

const searchFunction = () => {
  if (searchQuery.value && typeof searchQuery.value === 'string') {
    const query = searchQuery.value.toLowerCase();
    console.log('Search query:', query);

    visibleData.value = backlinksArray.value.filter(link => {
      const anchorMatch = link.anchor && link.anchor.toLowerCase().includes(query);
      const domainMatch = link.domain_from && link.domain_from.toLowerCase().includes(query);

      return anchorMatch || domainMatch;
    });
  } else {
    visibleData.value = backlinksArray.value;
  }
}




const projectDataByWeeks = ref([]);

const getBacklinkOverview = async () => {
  isLoading.value = true;
  try {
    const response = await axiosIns.get('/backlink/get-backlink-data', {
      params: { backlink_id: router.currentRoute.value.params.id }
    });

    isLoading.value = false;

    backlinks.value = response.data.data.backlink ?? [];
    summary.value = response.data.data.summary ?? {};
    backlinksArray.value = response.data.data.project ?? [];
    isProjectSaved.value = backlinks.value.is_project_created == 1;

    const projectDataDifferences = [];

    function getWeeksDifference(date1, date2) {
      const oneWeekInMs = 1000 * 60 * 60 * 24 * 7;
      return Math.floor((date2 - date1) / oneWeekInMs);
    }

    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const currentDate = new Date();
    const projects = response.data.data.project ?? [];

    const dateDataMap = new Map();

    projects.forEach(project => {
      const firstSeenDate = new Date(project.first_seen);
      if (isNaN(firstSeenDate.getTime())) {
        console.warn('Invalid first_seen date for project:', project);
        return;
      }

      const weeksSinceFirstSeen = getWeeksDifference(firstSeenDate, currentDate);
      const interval = Math.floor(weeksSinceFirstSeen / 2);
      const startDate = formatDate(new Date(firstSeenDate.getTime() + interval * 2 * 7 * 24 * 60 * 60 * 1000));

      // Initialize the entry in the map if it doesn't exist
      if (!dateDataMap.has(startDate)) {
        dateDataMap.set(startDate, { new: 0, lost: 0 });
      }

      // Update counts based on the project data
      const dateData = dateDataMap.get(startDate);
      if (project.is_new) dateData.new += 1;
      if (project.is_lost) dateData.lost += 1;
    });

    // Prepare data for the chart, filtering out entries with no data
    negativeColumnOptions.value.xaxis.categories = [];
    const newData = [];
    const lostData = [];

    dateDataMap.forEach((data, date) => {
      if (data.new !== 0 || data.lost !== 0) {
        negativeColumnOptions.value.xaxis.categories.push(date); // Add the date to x-axis categories
        newData.push(data.new);  // Add the new count for this date
        lostData.push(data.lost); // Add the lost count for this date
      }
    });

    // Update the chart series with stacked data
    negativeColumnSeries.value = [
      { name: 'New', data: newData },
      { name: 'Lost', data: lostData }
    ];


    seriesYears.value = [
      summary.value.backlinks ?? 0,
      summary.value.broken_backlinks ?? 0,
      summary.value.external_links_count ?? 0,
      summary.value.internal_links_count ?? 0,
    ];

    seriesDonut.value = [
      summary.value.referring_links_types?.redirect ?? 0,
      summary.value.referring_links_types?.anchor ?? 0,
      summary.value.referring_links_types?.image ?? 0,
      summary.value.referring_links_types?.canonical ?? 0,
    ];

    loadMoreData();

  } catch (error) {
    router.push('/backlink-overview');
    console.error('Error fetching backlink overview:', error);
    throw error;
  }
};

const saveProject = async () => {
  try {
    await axiosIns.post('/backlink/save-backlink-project', {
      backlink_id: Number(router.currentRoute.value.params.id)
    });
    backlinks.value.is_project_created = 1;

    toast.success('Project saved successfully');
    setTimeout(() => {
      router.push('/backlinks-projects-list');
    }, 2000);

  } catch (error) {
    toast.error('Failed to save project');
    console.error('Error saving project:', error);
    throw error;
  }
}

const deleteBacklinkOverview = async (id) => {
  try {
    //await axiosIns.delete(`/backlink/delete-backlink-project/${Number(id)}`);
    console.log('Project deleted successfully');
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  };

  return date.toLocaleString('en-US', options);
};

const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num?.toString();
};

const formatWithCommas = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatSummaryValue = (value) => {
  return formatWithCommas(value);
};

const chartLabelsDonut = ref([
  'Referring Links by Redirect',
  'Referring Links by Anchor',
  'Referring Links by Image',
  'Referring Links by Canonical',
]);

const chartOptionsDonut = ref({
  chart: {
    type: 'donut',
    toolbar: { show: false },
    height: 400,
  },
  plotOptions: {
    pie: {
      startAngle: -90,
      endAngle: 90,
      donut: {
        size: '70%',
        labels: {
          show: true,
          total: {
            show: true,
            label: 'Referring Links by Type',
            fontSize: '14px',
            fontWeight: '400',
            color: '#9E9E9E',
            formatter: function () {
              return seriesDonut.value
                .reduce((a, b) => a + b, 0)
                .toLocaleString();
            },
          },
        },
      },
    },
  },
  stroke: {
    show: true,
    width: 3,
    colors: '#fff',
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      formatter: (value) => value.toLocaleString(),
    },
  },
  legend: {
    show: false,
  },
  fill: {
    colors: ['#F3896F', '#F3AD6F', '#F3CD6F', '#8AC472'],
  },
  labels: chartLabelsDonut.value,
});

const chartOptionsYears = ref({
  chart: {
    type: 'donut',
    toolbar: { show: false },
    height: 312,
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        labels: {
          show: false,
          total: {
            show: true,
            label: '🔗',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000',
            formatter: function () {
              return seriesYears.value
                .reduce((a, b) => a + b, 0)
                .toLocaleString();
            },
          },
        },
      },
    },
  },
  stroke: {
    show: true,
    width: 3,
    colors: '#fff',
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      formatter: (value) => value.toLocaleString(),
    },
  },
  legend: {
    show: false,
  },
  fill: {
    colors: ['#68D2F5', '#90D2FF', '#3C8DC7', '#59B0EC'], // Same sky blue palette
  },
  labels: ['Total Backlinks', 'Broken Backlinks', 'External Links Count', 'Internal Links Count'],
});

const id = ref("")

onBeforeMount(() => {
  getBacklinkOverview();
})
onMounted(async () => {
  id.value = router.currentRoute.value.params.id
  await getBacklinkOverview();
});

watch([allChecked, newChecked, lostChecked], () => {
  updateChartData();
});
// watch(searchQuery, () => {
//   setFilter(null);
// });
onUnmounted(() => {
  console.log('Unmounted', backlinks.value.is_project_created);

  if (backlinks.value.is_project_created !== 1) {
    //deleteBacklinkOverview(id.value)
  }
})
</script>

<style scoped>
.medium-chart-card {
  margin-bottom: 18px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 12px;
  width: 450px;
}

.bold-text {
  font-weight: bold !important;
}

.chart-header {
  text-align: center;
  margin-bottom: 8px;
}

.chart-header h5 {
  margin: 0;
  font-size: 15px;
  color: #555;
}

.chart-body {
  display: flex;
  flex-direction: column;
}

.chart-wrapper {
  display: flex;
  align-items: center;
}

.chart-container {
  flex: 1;
  max-width: 350px;
}

.copyright-cont {
  margin-top: 12px;
  text-align: center;
}

.copyright-text {
  font-size: 12px;
  color: #888;
}

.skeleton-cell {
  padding: 10px;
}

.skeleton-loader {
  height: 20px;
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Skeleton animation */
@keyframes pulse {
  0% {
    background-color: #eee;
  }

  50% {
    background-color: #ccc;
  }

  100% {
    background-color: #eee;
  }
}
</style>
