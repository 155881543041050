<template>
  <!-- <Dialog :visible="visible" @update:visible="updateVisible" :style="{ width: '90%', maxWidth: '924px' }" class="common-modal-cont modal"> -->
  <Dialog :visible="visible" @update:visible="updateVisible" class="common-modal-cont modal">
    <div class="modal-dialog modal-924 modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Start Checking Keyword Ranking</span>
              <!-- <div class="info-cont tooltip-trigger">
                <img src="images/icon-info-circle.svg" alt="Info" />
                <div class="custom-tooltip" style="display: none">
                  <div class="tooltip-cont">
                    <img class="icon" src="images/icon-info-circle-white.svg" alt="Info" />
                    <span class="text">{{ tooltip }}</span>
                  </div>
                </div>
              </div> -->
            </h5>
            <a class="btn-close" @click="closeModal" aria-label="Close">
              <img src="images/icon-popup-close.svg" alt="Close" />
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont check-serp-form-cont">
              <div class="row">

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Your Domain/URL</label>
                    <input v-model="form.domainUrl" :class="{ 'is-invalid': domainValidation }" @input="validateDomain"
                      id="domainUrl" type="text" class="form-control" placeholder="Enter Your Domain" />
                    <div v-if="domainValidation" class="invalid-feedback">{{ validationMessage }}</div>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Search Engine</label>
                    <select class="form-control" v-model="form.searchEngine" :disabled="userData.type == 1">
                      <option>Select</option>
                      <option v-for="engine in searchEngine" :key="engine.id" :value="engine.id">{{ engine.name }}
                      </option>
                    </select>
                    <span class="required-alert" v-show="userData.type == 1">Upgrade to view more option</span>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Device Type</label>
                    <select class="form-control" v-model="form.deviceType" :disabled="userData.type == 1">
                      <option>Select</option>
                      <option v-for="device in deviceType" :key="device.id" :value="device.id">{{ device.name }}
                      </option>
                    </select>
                    <span class="required-alert" v-show="userData.type == 1">Upgrade to view more option</span>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Languages</label>
                    <select class="form-control" v-model="form.language" :disabled="userData.type == 1">
                      <option>Select Language</option>
                      <option v-for="language in languages" :key="language.code" :value="language.code">{{ language.name
                        }}
                      </option>
                    </select>
                    <span class="required-alert" v-show="userData.type == 1">Upgrade to view more option</span>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Keywords</label>
                    <Textarea class="form-control" v-model="form.keywords"
                      placeholder="Enter keywords, Each on a new line"></Textarea>
                  </div>

                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Competitors</label>
                    <Textarea class="form-control" v-model="form.competitors" :disabled="userData.type == 1"
                      placeholder="Enter Competitors, Each on a new line"></Textarea>
                    <span class="required-alert" v-show="userData.type == 1">Upgrade to view more option</span>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Country</label>
                    <select class="form-control" v-model="form.country" @change="onCountryChange"
                      :class="{ 'is-invalid': countryValidation }">
                      <option>Select a Country</option>
                      <option v-for="country in regionList" :key="country.country_iso_code" :value="country">
                        {{ country.location_name }}
                      </option>
                    </select>
                    <div v-if="countryValidation" class="invalid-feedback">Please select Country.
                    </div>
                  </div>
                </div>

                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Search Location</label>
                    <div class="autocomplete">
                      <input v-model="searchQuery" @input="onChange" type="text"
                        placeholder="Enter State (e.g., Florida) and City (e.g., Miami)..." class="form-control"
                        :disabled="form.country === null" />
                      <ul v-show="showResults" class="autocomplete-results">
                        <li v-for="(result, index) in searchResults" :key="index" class="autocomplete-result"
                          @click="selectResult(result)">
                          {{ result.location_name }}
                        </li>
                        <li v-if="searchResults?.length === 0 && searchQuery.trim() !== ''"
                          class="autocomplete-no-results">
                          No results found
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button class="admin-common-btn btn-bordered btn btn-secondary" type="button" @click="resetConfirm">
              Reset
            </Button>
            <Button class="admin-common-btn btn btn-primary" type="submit" :loading="isLoading">
              Submit
            </Button>
            <div class="mobile-close-modal">
              <a class="btn-close" @click="closeModal" aria-label="Close">
                <img src="images/icon-popup-close.svg" alt="Close" />
              </a>
            </div>
          </div>

        </form>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, watch } from 'vue';
import CountryService from '@/service/CountryService';
import SerpService from '@/service/SerpService';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import EventBus from '@/EventBus';


const countryService = new CountryService();
const serpService = new SerpService();

const regionList = ref(null);
const cityStateData = ref([]);
const isLoading = ref(false)

const form = ref({
  type: 1,
  random_id: '',
  language: 'en',
  searchEngine: 1,
  deviceType: 1,
  domainUrl: '',
  keywords: '',
  competitors: '',
  country: null,
  state_city: ''
});

const submitted = ref(false);

// Computed properties for validation
// const domainValidation = computed(() => submitted.value && !form.value.domainUrl);
const languageValidation = computed(() => submitted.value && !form.value.language);
const countryValidation = computed(() => submitted.value && !form.value.country);
const domainValidation = computed(() => {
  const domainRegex = /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  return !domainRegex.test(form.value.domainUrl);
});


const validationMessage = computed(() => {
  return domainValidation.value ? "Please enter a valid domain (e.g., example.com). 'www' is not allowed." : "";
});

const isFormValid = computed(() => {
  return !languageValidation.value && !countryValidation.value && !domainValidation.value;
});

// Watch isFormValid and set isLoading to false if the form is valid
watch(isFormValid, (newValue) => {
  if (newValue) {
    isLoading.value = false;
  }
});

const languages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'sq', name: 'Albanian' },
  { code: 'am', name: 'Amharic' },
  { code: 'ar', name: 'Arabic' },
  { code: 'hy', name: 'Armenian' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'eu', name: 'Basque' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ceb', name: 'Cebuano' },
  { code: 'ny', name: 'Chichewa' },
  { code: 'zh', name: 'Chinese (Simplified)' },
  { code: 'zh-TW', name: 'Chinese (Traditional)' },
  { code: 'co', name: 'Corsican' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'et', name: 'Estonian' },
  { code: 'tl', name: 'Filipino' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'fy', name: 'Frisian' },
  { code: 'gl', name: 'Galician' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'ht', name: 'Haitian Creole' },
  { code: 'ha', name: 'Hausa' },
  { code: 'haw', name: 'Hawaiian' },
  { code: 'iw', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hmn', name: 'Hmong' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'ig', name: 'Igbo' },
  { code: 'id', name: 'Indonesian' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'jw', name: 'Javanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'km', name: 'Khmer' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'ko', name: 'Korean' },
  { code: 'ku', name: 'Kurdish (Kurmanji)' },
  { code: 'ky', name: 'Kyrgyz' },
  { code: 'lo', name: 'Lao' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'Latvian' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mt', name: 'Maltese' },
  { code: 'mi', name: 'Maori' },
  { code: 'mr', name: 'Marathi' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'my', name: 'Myanmar (Burmese)' },
  { code: 'ne', name: 'Nepali' },
  { code: 'no', name: 'Norwegian' },
  { code: 'or', name: 'Odia (Oriya)' },
  { code: 'ps', name: 'Pashto' },
  { code: 'fa', name: 'Persian' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pa', name: 'Punjabi' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sm', name: 'Samoan' },
  { code: 'gd', name: 'Scots Gaelic' },
  { code: 'sr', name: 'Serbian' },
  { code: 'st', name: 'Sesotho' },
  { code: 'sn', name: 'Shona' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'si', name: 'Sinhala' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'so', name: 'Somali' },
  { code: 'es', name: 'Spanish' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sw', name: 'Swahili' },
  { code: 'sv', name: 'Swedish' },
  { code: 'tg', name: 'Tajik' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'th', name: 'Thai' },
  { code: 'tr', name: 'Turkish' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'ug', name: 'Uyghur' },
  { code: 'uz', name: 'Uzbek' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'cy', name: 'Welsh' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Yiddish' },
  { code: 'yo', name: 'Yoruba' },
  { code: 'zu', name: 'Zulu' }
];

const searchEngine = ref([
  { id: 1, name: 'Google' },
  { id: 2, name: 'Yahoo' },
  { id: 3, name: 'Bing' }
]);

const deviceType = ref([
  { id: 1, name: 'Desktop' },
  { id: 2, name: 'Tablet' },
  { id: 3, name: 'Mobile' }
]);

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: "Check SERP"
  },
  tooltip: {
    type: String,
    default: "CHECK SERP"
  }
});

const emit = defineEmits(['update:visible']);

const submitForm = () => {
  submitted.value = true;

  isLoading.value = true

  if (domainValidation.value || languageValidation.value || countryValidation.value) {
    return;
  }
  const formData = {
    random_id: generateRandomId(),
    type: form.value.type,
    deviceType: form.value.deviceType,
    language: form.value.language,
    domainUrl: form.value.domainUrl,
    keyword: form.value.keywords ? form.value.keywords.split('\n').filter(keyword => keyword.trim() !== '') : [],
    competitor: form.value.competitors ? form.value.competitors.split('\n').filter(competitor => competitor.trim() !== '') : [],
    country: form.value.country ? Number(form.value.country.location_code) : null,
    state_city: form.value.state_city ? form.value.state_city.location_code : null,
    location_name: form.value.state_city ? form.value.state_city.location_name : null,
    option_location: form.value.country ? form.value.country.location_name : null
  };

  if (formData.keyword.length > 100) {
    // Display a message indicating that the limit is exceeded
    toast.error(`Serpplus does not support more than 100 keywords. Please reduce the number of keywords.`, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 6000 milliseconds timeout
    });
    isLoading.value = false;
    return;
  }

  if (userData.value.type == 1 && formData.keyword.length > 20) {
    // Display a message tailored for trial users
    toast.error(`As a trial user, Serpplus supports a maximum of 20 keywords. Please reduce the number of keywords.`, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000 // 6000 milliseconds timeout
    });
    isLoading.value = false;
    return;
  }


  serpService
    .checkSerp(formData)
    .then((data) => {
      setTimeout(() => {
        EventBus.$emit('loadSerps', true);
      }, data.data);

      // Display success toast message
      toast.success('Keywords are in queue. Please wait...', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 3000 milliseconds timeout
      });

      isLoading.value = false
      emit('update:visible', false);
    })
    .catch((error) => {
      let errorMessage = '';
      if (error.response && error.response.data && error.response.data.message === 'Insufficient credits') {
        errorMessage = 'Insufficient credits. Upgrade to premium for more features.';
      } else {
        errorMessage = 'An error occurred while processing your request. Please try again later.';
      }
      // Display error toast message
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000 // 6000 milliseconds timeout
      });
      isLoading.value = false
    });
};


const generateRandomId = () => {
  const randomString = Math.random().toString(36).substring(2, 8);

  const randomNumber1 = Math.floor(Math.random() * 10);
  const randomNumber2 = Math.floor(Math.random() * 10);

  const randomId = randomString + randomNumber1 + randomNumber2;

  return randomId;
};

const pageSize = 20;
const currentPage = ref(1);
const searchQuery = ref('');
const showResults = ref(false);

const searchResults = computed(() => {
  if (!searchQuery.value.trim()) {
    return [];
  } else {
    return filteredLocations.value.filter(location =>
      location.location_name.toLowerCase().includes(searchQuery.value.trim().toLowerCase())
    );
  }
});

const onChange = () => {
  showResults.value = true;
};

const selectResult = (result) => {
  searchQuery.value = result.location_name;
  showResults.value = false;
  form.value.state_city = result;
};

const onCountryChange = async () => {
  currentPage.value = 1; // Reset current page
  await fetchCityStateData();
};

const fetchCityStateData = async () => {

  try {
    const response = await countryService.getCityAndState(form.value.country.country_iso_code, currentPage.value, pageSize);
    if (currentPage.value === 1) {
      cityStateData.value = response;
    } else {
      cityStateData.value = [...cityStateData.value, ...response];
    }
  } catch (error) {
    console.error('Error fetching city data:', error);
  }
};

const visibleLocations = computed(() => {
  if (cityStateData.value) {
    return cityStateData.value.slice(0, currentPage.value * pageSize);
  } else {
    return [];
  }
});

const filteredLocations = computed(() => {
  if (!searchQuery.value) {
    return visibleLocations.value;
  } else {
    const query = searchQuery.value.toLowerCase();
    return cityStateData.value.filter(location => {
      return location.location_name.toLowerCase().includes(query);
    });
  }
});

const closeModal = () => {
  isLoading.value = false
  emit('update:visible', false);
};


const userData = ref(null)

onMounted(() => {

  EventBus.$on('addKeyword', data => {
    if (data) {
      form.value = {
        type: 1,
        random_id: '',
        language: 'en',
        searchEngine: 1,
        deviceType: 1,
        domainUrl: '',
        keywords: '',
        competitors: '',
        country: null,
        state_city: ''
      }
    }
  })
  countryService.getCountries().then((data) => {
    regionList.value = data.sort((a, b) => a.location_name.localeCompare(b.location_name));
  });
  const data = localStorage.getItem('userData');
  userData.value = JSON.parse(data);

});

const resetConfirm = () => {
  if (confirm("Are you sure you want to reset?")) {
    form.value = {
      type: 1,
      random_id: '',
      language: 'en',
      searchEngine: 1,
      deviceType: 1,
      domainUrl: '',
      keywords: '',
      competitors: '',
      country: null,
      state_city: ''
    }
  }
}

</script>



<style scoped>
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
  font-size: 1rem;
  /* Adjust font size for smaller screens */
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  background-color: #fff;
}

.autocomplete-result {
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #f0f0f0;
}

.autocomplete-no-results {
  padding: 8px;
  color: #999;
}

.modal-content {
  /* overflow-y: auto; */
  /* Enable vertical scroll if content exceeds modal height */
  /* max-height: calc(100vh - 200px); */
  /* Adjust max-height to leave space for header and footer */
  /* padding: 1rem; */
  /* Adjust padding for consistent spacing */
  /* width: auto; */
  /* Set width to 400px */
}

.autocomplete {
  position: relative;
}

.autocomplete input {
  /* width: 100%;
  padding: 8px; */
}

.autocomplete ul.autocomplete-results {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  width: calc(100% - 2px);
  max-height: 200px;
  /* Set a max height for the dropdown */
  overflow-y: auto;
  /* Enable vertical scrolling */
  z-index: 1000;
  /* Ensure it's above other elements */
}

.autocomplete-result {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #f0f0f0;
}

.autocomplete-no-results {
  padding: 8px;
}

/* Adjust width for smaller screens */
@media (max-width: 576px) {
  .modal-content {
    /* width: 400px; */
    /* Adjust width for smaller screens */
  }
}

/* Adjust width for medium-sized screens */
@media (min-width: 577px) and (max-width: 992px) {
  .modal-content {
    /* width: 600px; */
    /* Adjust width for medium-sized screens */
  }
}

/* Adjust width for large screens */
@media (min-width: 993px) {
  .modal-content {
    /* width: auto; */
    /* Adjust width for large screens */
  }
}

.modal-header,
.modal-footer {
  padding: 0.5rem 1rem;
  /* Adjust padding for header and footer */
}

.modal-footer .btn {
  margin: 0 0.25rem;
  /* Add margin to buttons to provide spacing */
}

/* CSS */
select:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}

input:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}

textarea:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}
</style>
