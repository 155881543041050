<template>

    <loader :active="loading" message="Please wait..." />

   

    <div class="admin-body">
        <div class="admin-body-box">
            <div class="admin-body-bottom">
                <div class="table-top">
                    <input class="table-search-input" placeholder="Search" v-model="searchQuery">
                </div>
                <div class="data-table-cont">
                    <div class="table-responsive table-manage-customers">
                        <table id="example" class="data-table table table-striped table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap min-width65">ID</th>
                                    <th class="no-wrap">Name</th>
                                    <th class="no-wrap">Credits</th>
                                    <th class="no-wrap">Plan</th>
                                    <th class="no-wrap">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="customer in customers" :key="customer.id">
                                    <td class="no-wrap">{{ customer.id }}</td>
                                    <td class="no-wrap">{{ customer.name }}</td>
                                    <td class="no-wrap">{{ customer.credits }}</td>
                                    <td class="no-wrap">{{ customer.plan_name }}</td>
                                    <td class="no-wrap">
                                        <div class="action-icon-cont"
                                            style="display: flex; justify-content: center;">
                                            <router-link :to="'/view-customer/' + customer.id"
                                                class="action-icon icon-view">
                                                <img class="icon-img" src="images/icon-view.svg" alt="View">
                                            </router-link>
                                            <a class="action-icon icon-send" @click="goToUserDashboard(customer.id)">
                                                <img class="icon-img"  style="cursor: pointer;" src="images/icon-send.svg" alt="Send">
                                            </a>
                                            <a class="action-icon icon-delete" @click="deleteCustomer(customer.id)"
                                                style="cursor: pointer;">
                                                <img class="icon-img" src="images/icon-delete.svg" alt="Delete">
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="pagination-container">
                        <PaginationComponent :currentPage="currentPage" :lastPage="lastPage" :projectId="projectId" :fetchPage="fetchCustomers" />
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="pagination-container">
        <PaginationComponent :currentPage="currentPage" :lastPage="lastPage" :projectId="projectId" :fetchPage="fetchCustomers" />
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
    
</template>


<script setup>
import { ref, onMounted, onBeforeMount, inject, watch } from 'vue';
import { useAbility } from '@casl/vue';
import axiosIns from '@/axios';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';
const { can } = useAbility();
const ability = inject(ABILITY_TOKEN)
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import PaginationComponent from "@/components/PaginationComponent.vue";
import loader from '../Loader.vue';


const currentPage = ref(1);
const lastPage = ref(1);
const loading = ref(false)
const customers = ref([{}]);
const searchQuery = ref('');

async function fetchCustomers(page) {
    loading.value = true
    try {
        const response = await axiosIns.get(`/customers?page=${page}&search=${searchQuery.value}`);
        loading.value = false
        currentPage.value = Number(response.data.current_page)
        lastPage.value = Number(response.data.last_page)
        customers.value = response.data.data;
    } catch (error) {
        console.error('Error fetching Customers:', error);
        return [];
    }
}

const deleteCustomer = async (id = null) => {
    // Ask for confirmation
    const confirmDelete = window.confirm('Are you sure you want to delete this customer?');
    if (!confirmDelete) {
        return; // Do nothing if user cancels the deletion
    }

    try {
        await axiosIns.delete(`/user/${id}`);
        customers.value = customers.value.filter((c) => c.id !== id);
        toast.success('Customer Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        toast.error('Error Deleting Customer', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        console.error('Error Deleting Customer:', error);
    }
};


const goToUserDashboard = async (id) => {
    const response = await axiosIns.get(`auth/change-auth/${id}`);

    const accessToken = response.data.accessToken;
    const userAbilities = response.data.userAbilities;
    const userData = response.data.userData;

    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('isAdmin', true);
    toast.success('Login Successfully', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000
    });
    // It's a good practice to use `await` with asynchronous functions to ensure proper execution
    await getUserAbilities();

    // Redirect to the desired page after successful login
    window.location.href = '/view-serps'; // Redirect to the root URL    
}

async function getUserAbilities() {
    try {
        const abilitiesJson = localStorage.getItem('userAbilities');

        if (abilitiesJson) {
            const abilities = JSON.parse(abilitiesJson);

            const { can, rules } = new AbilityBuilder(createMongoAbility);
            can(abilities);

            ability.update(rules);
        } else {
            console.error('User abilities not found in localStorage');
        }
    } catch (error) {
        console.error('Error retrieving user abilities:', error);
    }
}

watch(searchQuery, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        currentPage.value = 1; // Reset current page when search query changes
        fetchCustomers(currentPage.value)
    }
});
onMounted(async () => {
    await fetchCustomers(currentPage.value);
});

</script>