<template>
    <div class="backlink-projects-page-cont">
        <div class="backlink-table-cont">
            <div class="backlink-table-box">
                <div class="box-top box-top-with-filter-new">
                    <div class="table-top-bar">
                        <div class="top-left">
                            <div class="form-group search-group">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchQuery"
                                    @input="filterProjects" />
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn search-btn">Search</button>
                            </div>
                            <!-- <div class="form-group btn-group">
                                <div class="custom-form-check-new form-check-inline">
                                    <input class="form-check-input check-blue" type="checkbox" v-model="selectAll">
                                    <label class="form-check-label">Select All</label>
                                </div>
                            </div> -->
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn export-btn">
                                    <img class="flag-img img-fluid"
                                        src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-excel-export.svg"
                                        alt="Icon Excel Export">
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="data-table-cont">
                        <div class="table-responsive table-normal-view table-backlink">
                            <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                                <thead>
                                    <tr>
                                        <th class="no-wrap" style="width: 50%!important;">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Domain</span>
                                                    <img class="img-fluid th-icon"
                                                        src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-question-circle.svg"
                                                        alt="Question Icon" title="Domain">
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Date Created</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-center">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Open Project</span>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="no-wrap align-center" style="width: 67px;">
                                            <div class="th-short-box">
                                                <div class="txt-icon-cont">
                                                    <span class="th-txt">Remove</span>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(project, index) in paginatedProjects" :key="index">
                                        <td class="align-left no-wrap">
                                            <!-- <input class="table-checkbox checkbox-new form-control" type="checkbox"> -->
                                            {{ project.target }}
                                        </td>
                                        <td class="align-left">{{ new Date(project.created_at).toLocaleDateString() }}
                                        </td>
                                        <td class="align-center">
                                            <p class="text-highlight">
                                                <RouterLink :to="`/backlink-view/${project.id}`" class="text-primary">
                                                    Open <img class="img-fluid"
                                                        src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-open-link.svg"
                                                        alt="Open Link Icon" title="Open">
                                                </RouterLink>
                                            </p>
                                        </td>
                                        <td class="align-center">
                                            <a class="table-action-btn-new" href="#"
                                                @click.prevent="deleteBacklinkProject(project.id)" title="Remove">
                                                <img class="img-fluid" src="http://serpluspromotion.s3-website.ap-south-1.amazonaws.com/images/icon-bin.svg"
                                                    alt="Remove Icon">
                                            </a>
                                        </td>

                                    </tr>
                                    <tr v-if="isLoading">
                                        <td colspan="4" class="text-center">
                                            <p style="font-size: 1.2em;">⏳ Loading...</p>
                                        </td>
                                    </tr>
                                    <tr v-if="!isLoading && paginatedProjects.length === 0">
                                        <td colspan="4" class="text-center">
                                            <p style="font-size: 1.2em;">🧐 No Data Available</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-container">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <!-- <li class="page-item previous" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" @click="changePage(currentPage - 1)">Previous</a>
                                    </li> -->
                                    <li v-for="page in totalPages" :key="page" class="page-item"
                                        :class="{ active: currentPage === page }">
                                        <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                    </li>
                                    <!-- <li class="page-item next" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" @click="changePage(currentPage + 1)">Next</a>
                                    </li> -->
                                    <li class="page-item">
                                        <a class="page-link">2</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link">3</a>
                                    </li>
                                    <li class="page-item active view-all">
                                        <a class="page-link">View All</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Copyright Section -->
    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axiosIns from '@/axios';
import { RouterLink } from 'vue-router';
import { toast } from 'vue3-toastify';

const backlinkProjects = ref([]);
const filteredProjects = ref([]);
const searchQuery = ref('');
const currentPage = ref(1);
const perPage = ref(10);
const isLoading = ref(false);

const paginatedProjects = computed(() => {
    const start = (currentPage.value - 1) * perPage.value;
    const end = start + perPage.value;
    return filteredProjects.value.slice(start, end);
});

const totalPages = computed(() => {
    return Math.ceil(filteredProjects.value.length / perPage.value);
});

const getBacklinkProjects = async () => {
    isLoading.value = true;
    try {
        const response = await axiosIns.get('/backlink/get-backlink-projects');
        backlinkProjects.value = response.data.data;
        filteredProjects.value = backlinkProjects.value;
        isLoading.value = false;
    } catch (error) {
        console.error('Error fetching backlink projects:', error);
    }
};

const filterProjects = () => {
    const query = searchQuery.value.toLowerCase();
    filteredProjects.value = backlinkProjects.value.filter((project) =>
        project.target.toLowerCase().includes(query)
    );
    currentPage.value = 1;
};

const deleteBacklinkProject = async (id) => {
    const confirmDelete = confirm('Are you sure you want to delete this project?');

    if (!confirmDelete) {
        return;
    }

    try {
        await axiosIns.delete(`/backlink/delete-backlink-project/${Number(id)}`);
        toast.success('Project deleted successfully');

        backlinkProjects.value = backlinkProjects.value.filter(project => project.id !== id);
        filteredProjects.value = filteredProjects.value.filter(project => project.id !== id);

        if (paginatedProjects.value.length === 0 && currentPage.value > 1) {
            currentPage.value--;
        }

    } catch (error) {
        console.error('Error deleting project:', error);
        toast.error('Failed to delete project');
    }
};


const changePage = (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
    }
};

onMounted(async () => {
    await getBacklinkProjects();
});
</script>
